import TextInput from "../inputs/TextInput";
import Greeting from "../layouts/Greeting";
import Select from 'react-select';
import { AiOutlineDelete, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { MdDelete, MdEdit } from 'react-icons/md';
import UploadButton from "../layouts/UploadButton";
import PreviewPicture from "../layouts/PreviewPicture";
import { get, put } from "../../utility/fetch";
import { post } from '../../utility/fetch';
import { useEffect, useState } from "react";
import { Pagination, Spin, Table, Tooltip } from "antd";
import DocumentModal from "../layouts/DocumentModal";
import { months, years } from "../../utility/general";
import { getDeptName } from "../../utility/general";
import TextareaInput from "../inputs/TextareaInput";
import { GiCancel } from "react-icons/gi";
import { IoIosCheckmark, IoIosReturnLeft } from "react-icons/io";
import notification from "../../utility/notification";
import ReviewForm from "../layouts/ReviewForm";








const OtherFinancialReviewDFA = () => {

    const [mdas, setMdas] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [paginationLength, setPaginationLength] = useState(0)
    const [pageSize, setPageSize] = useState(30);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalData, setModalData] = useState({});
    const [allData, setAllData] = useState({});
    const [documentType, setDocumentType] = useState("")
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [details, setDetails] = useState([]);
    const [type, setType] = useState("");
    const [loading, setLoading] = useState("");

    const [payload, setPayload] = useState({
        mdaId: 0,
        year: 0,
        adminCode: "",
        month: 0,
        comment: "",
        uploadUrl: [

        ]
    });
    const [tables, setTables] = useState([]);


    useEffect(() => {
        setTables(
            pendingRequests.map((entry) => (
                {
                    adminCode: entry?.adminCode,
                    year: entry?.year,
                    month: months[entry?.month]?.label,
                    mda: entry?.mda,
                    comment: entry?.comment,
                    all: entry
                }
            ))
        )

    }, [pendingRequests])

    const toggleReviewModal = () => {
        setShowReviewForm(!showReviewForm);
    }

    const getVouchers = async () => {
        let res = await get(`/Appropriation/Form/FinancialReport?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            setPendingRequests(res?.data?.items);
            console.log(res.data?.items);
        }
    }


    const approve = async (data, comment) => {
        // setLoading(true);
        // const { voucher, schedule } = comment;
        console.log(data)

        const payload = {
            id: data?.all?.id,
            comment
        }

        let load = {
            month: "string",
            year: 0,
            uploadUrl: [ 
            ],
            comment: comment
        }



        // try {
        //     const res = await put(
        //         '/Appropriation/FinancialReport/Approve',
        //         payload,
        //         true
        //     );
        //     if (res.status === 'success') {
        //         notification({
        //             type: 'success',
        //             message: "Transaction Approved Successfully"
        //         });
        //         getVouchers();

        //         setLoading(false);
        //     }
        //     else {
        //         console.log(res)
        //         notification({
        //             type: 'error',
        //             message: res.message
        //         });
        //         setLoading(false);
        //     }
        // } catch (error) {
        //     console.log(error);
        //     setLoading(false);
        // }
        // setLoading(false);
    };


    const decline = async (data, comment) => {
        const payload =
            [
                {
                    id: data?.all?.id,
                    comment
                }
            ]

        try {
            const res = await put(
                '/Appropriation/FinancialReport/Reject',
                payload,
                true
            );
            if (res.status === 'success') {
                notification({
                    type: 'success',
                    message: "Transaction Declined Successfully"
                });
                getVouchers();

                setLoading(false);
            }
            else {
                console.log(res)
                notification({
                    type: 'error',
                    message: res.message
                });
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        setLoading(false);
    };


    const review = async (data, comment) => {
        const payload =
            [
                {
                    id: data?.all?.id,
                    comment
                }
            ]

        try {
            const res = await put(
                '/Appropriation/FinancialReport/Review',
                payload,
                true
            );
            if (res.status === 'success') {
                notification({
                    type: 'success',
                    message: "Transaction sent back for review successfully"
                });
                getVouchers();

                setLoading(false);
            }
            else {
                console.log(res)
                notification({
                    type: 'error',
                    message: res.message
                });
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        setLoading(false);
    };

    const getSavedDocs = async (mdaId) => {
        let res = await get(`/Appropriation/Form/FinancialReport/Role?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            setPendingRequests(res?.data?.items);
            console.log(res.data?.items)
        }
    }


    const viewDocuments = (type, data) => {
        console.log(data)
        setModalData(data?.voucher);
        setAllData(data)
        setDocumentType(type);
        setShowPictureModal(true);
    }

    const columns = [
        {
            title: 'MDA',
            dataIndex: 'mda',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'ADMINISTRATIVE CODE',
            dataIndex: 'adminCode',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'MONTH',
            dataIndex: 'month',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'YEAR',
            dataIndex: 'year',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'COMMENT',
            dataIndex: 'comment',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DOCUMENTS',
            render: (text, record) => (
                <div>
                    {
                        <div className="flex space-around">
                            <button className="w-100 btn" onClick={() => { viewDocuments("docs", record) }}>View Documents</button>
                        </div>
                    }
                </div>
            )
        },
        {
            title: 'ACTION',
            render: (text, record) => (
                <div>
                    {
                        <div className="flex space-around">
                            {
                                <div className="flex space-around">
                                    {/* <Tooltip title="Decline"><GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("decline") }} /></Tooltip> */}
                                    <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("approve") }} /></Tooltip>
                                    <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("review") }} /></Tooltip>

                                </div>
                            }
                        </div>
                    }
                </div>
            )
        },
    ]



    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };


    const getMdas = async () => {
        let res = await get("/Mdas");
        setMdas(res?.data?.map((mda) => (
            {
                value: mda?.id,
                label: mda?.name
            }
        )))

    }

    const handleMda = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                mdaId: value?.value
            }
        ))

    }

    // const handleDocumentMda = (value) => {
    //     getDocumentByMda(value?.value);
    // }

    const handleAdminCode = (e) => {

        setPayload((prev) => (
            {
                ...prev,
                adminCode: e?.target?.value
            }
        ))
    }

    const handleComment = (e) => {

        setPayload((prev) => (
            {
                ...prev,
                comment: e?.target?.value
            }
        ))
    }

    const handleYear = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                year: value?.value
            }
        ))

    }

    const handleMonth = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                month: value?.value
            }
        ))

    }


    const recImagg = (value) => {
        console.log(value);
        let tempDocument = payload?.uploadUrl;
        tempDocument.push(
            {
                docName: value?.docTitle,
                docUrl: value?.docPath
            }
        )

        setPayload((prev) => (
            {
                ...prev,
                uploadUrl: tempDocument
            }
        ))

    };


    const del = (id) => {
        let tempArray = payload?.uploadUrl;


        let filteredArray = tempArray.filter((item, idx) => {
            return idx !== id
        })

        setPayload((prev) => (
            {
                ...prev,
                uploadUrl: filteredArray
            }
        ))
    };

    const submitOtherFlow = async (e) => {
        e?.preventDefault();
        let res = await post('/Appropriation/FinancialReport/Form', payload)
        console.log(res);
    }



    useEffect(() => {
        getSavedDocs();
        getMdas();
    }, [])


    return (
        <div className="w-100">
            <Greeting />
            <hr />

            <div className="p-10 m-t-40">
                <Table
                    className='w-100 m-t-20'
                    bordered
                    dataSource={tables && tables}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 400 }}
                    loading={{
                        indicator: <div> <Spin /> </div>, spinning: tableLoading
                    }}
                />
                {paginationLength > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Pagination
                            showSizeChanger
                            pageSize={pageSize}
                            onShowSizeChange={paginationChangeHandler}
                            current={pageNumber}
                            total={paginationLength}
                            // showTotal={true}
                            onChange={paginationChangeHandler}
                            // pageSizeOptions={pageSizeOptions}
                            responsive
                        />
                    </div>
                )}
            </div>
            {
                showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData?.all} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
            }

            {showReviewForm && <ReviewForm closeModal={toggleReviewModal} approve={approve} review={review} decline={decline} details={details} type={type} loading={loading} />}
        </div>
    )
}

export default OtherFinancialReviewDFA;