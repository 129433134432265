/* eslint-disable */
import React, { Component } from "react";
// import { store } from "react-notifications-component";
import queryString from "query-string";
// import Cookies from "js-cookie";
import axios from "axios";
// import moment from "moment";
import LoadingSpinner from "../../utility/loadingSpinner";

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      result: "",
      loading: false
    };
  }

  checkurl = async () => {
    const parsed = queryString.parse(this.props.location.search);

    const { history } = this.props;
    //get the base url from query string(edogoverp.com)
    const baseurl = parsed.base;
    // get the help Link
    // Cookies.set("helpLink", parsed.help);
    // Cookies.set("emp", parsed.emp);

    if (parsed.emp !== "") {
      this.setState({
        loading: true
      });



      const authurl = "/treasuryrevamped/api/Authentication/AccessToken"; //change this to the module authenthication endpoint
      axios
        .post(baseurl + authurl + "?Token=" + parsed.emp, {
          headers: {
            "Access-Control-Allow-Credentials": true,
            crossorigin: true,
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Origin": "*"
          }
        })

        .then((user_auth) => {
          console.log(user_auth);
          localStorage.setItem('token', user_auth.data.data.token);
          localStorage.setItem("baseURL", baseurl)
          localStorage.setItem("access", user_auth.data.data.accesslevel)
          localStorage.setItem("mda", user_auth.data.data.mda)
          localStorage.setItem("homeLink", user_auth.data.data.homeLink)
          // localStorage.setItem('USER_INFO', JSON.stringify(data));
          // localStorage.setItem(ModuleName + "userId", user_auth.data.data.id);
          localStorage.setItem("userId", user_auth.data.data.userID);
          localStorage.setItem("role", user_auth.data.data.role);
          localStorage.setItem("email", user_auth.data.data.email);
          localStorage.setItem("name", user_auth.data.data.name);
          localStorage.setItem("profilePicture", user_auth.data.data.profilePicture);
          //localStorage.setItem(ModuleName + "_FirstName", user_auth.data.firstName);
          localStorage.setItem(
            "fullName",
            `${user_auth.data.data.firstName} ${user_auth.data.data.lastName}`
          );
          // localStorage.setItem("loginTime", moment().format("HH:mm a"));
          // localStorage.setItem(ModuleName + "_LastName", user_auth.data.lastName);
          localStorage.setItem("token", user_auth.data.data.token);
          localStorage.setItem("tokenExist", true);

          localStorage.setItem("homeLink", user_auth.data.data.homeLink); //for home redirection

          if (user_auth.data.data.token !== null && user_auth.data.data.token !== "") {
            const { history } = this.props;
            this.setState({ loading: true });
            if(user_auth?.data?.data?.accesslevel === "DFA" || user_auth?.data?.data?.accesslevel === "Initiator"){
              history.push("/appropriation/dashboard");
            }
            else{
              history.push("/appropriation/other-review");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  componentDidMount() {
    // location();
    this.checkurl();

  }

  render() {
    const { loading } = this.state;
    return (
      <div className="w-100 flex flex-v-center flex-h-center" style={{ minHeight: "105vh" }}>
        {loading ? <LoadingSpinner /> : ""}
      </div>
    );
  }
}

export default Connect;
