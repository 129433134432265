const SchedulePreview = ({
    schedule,
    paymentVoucher
}) => {

    const numberWithCommas = (x) => {
       
  let sanitizedValue = String(x).replace(/[^0-9.]/g, '');

  // Ensure there are only two decimal places
  if (sanitizedValue.includes('.')) {
    const parts = sanitizedValue.split('.');
    if (parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      sanitizedValue = parts.join('.');
    }
  }


        if (x === '') {
            return
        }
        if (String(x).includes(',')) {
            return String(sanitizedValue)?.replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return sanitizedValue?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        }



    }


    const stripCommas = (val) => {

        return parseFloat(String(val).replace(/,/g, ''))
    }


    return (
        <div className="page bg-white m-t-10">
            <h2 className="m-b-20">SCHEDULE NO. {schedule?.scheduleNO || "-"}</h2>
            <p className="m-b-20">ADMINISTRATIVE CODE <span className='w-45 filter_box_border_b'><span className='m-x-40 '>{schedule?.administrativeCode || schedule?.head}</span> <span className='m-r-40'></span></span></p>
            <p className="m-b-20 flex"> <p>THE TREASURY CASH OFFICER AT THE TREASURY CASH OFFICE </p> <p className='w-25r filter_box_border_b center-text'> B/C</p></p>
            <p className="m-b-20">IS AUTHORIZED TO MAKE THE FOLLOWING PAYMENTS CHARGEABLE TO THE ABOVE HEAD OF EXPENDITURE </p>
            <p className="m-b-20">GPB 86(76)177/50.000OL5</p>
            <table className='w-100 border-black'>
                <tr className='border-black'>
                    <th className='border-black' >Date</th>
                    <th className='border-black' >PV No.</th>
                    <th className='border-black' >Economic Code No.</th>
                    <th className='border-black' >Name of Vendor/Organization</th>
                    <th className='border-black' width="10%">Treasury Cash Office</th>
                    <th className='border-black' >Amount</th>
                    <th className='border-black' >For use by the treasury Cash Office</th>
                </tr>
                <tr>
                    <td className=' center-text border-black'>{new Date(schedule?.date).toLocaleDateString()}</td>
                    <td className=' center-text border-black'>{paymentVoucher?.pvNo}</td>
                    <td className=' center-text border-black'>{schedule?.economicCode || paymentVoucher?.economicCodeNo || schedule?.subhead || schedule?.economicCodeNo}</td>
                    <td className=' border-black w-25r'>
                        <p className='m-b-40 center-text  '>{schedule?.nameOfPayee?.toUpperCase()}</p>
                        <p className='m-b-40 center-text '>1 pv</p>
                        <p className='m-b-40 center-text '>({paymentVoucher?.amountInWords})</p>
                    </td>
                    <td className=' center-text border-black'>B/C</td>
                    <td className=' center-text border-black'>{`₦${numberWithCommas(schedule?.amount)}`}</td>
                    <td className='border-black' width="10%"></td>
                </tr>
                {paymentVoucher?.subVouchers?.length > 0 && paymentVoucher?.subVouchers?.map((schedule) => (
                    <tr>
                        <td className=' center-text border-black'>{new Date(schedule?.date).toLocaleDateString()}</td>
                        <td className=' center-text border-black'>{schedule?.pvNo}</td>
                        <td className=' center-text border-black'>{schedule?.economicCode || paymentVoucher?.economicCodeNo || schedule?.subhead}</td>
                        <td className=' border-black w-25r'>
                            <p className='m-b-40 center-text '>{schedule?.nameOfPayee?.toUpperCase() || schedule?.drTo?.toUpperCase()}</p>
                            <p className='m-b-40 center-text'>1 pv</p>
                            <p className='m-b-40 center-text'>({paymentVoucher?.amountInWords})</p>
                        </td>
                        <td className=' center-text border-black'>B/C</td>
                        <td className=' center-text border-black'>{(schedule?.amount === 0 || !schedule?.amountRequested) ? `₦${numberWithCommas(schedule?.amount)}` : `₦${numberWithCommas(schedule?.amountRequested)}`}</td>
                        <td className='border-black' width="10%"></td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={5}>TOTAL ₦</td>
                    {
                        (paymentVoucher?.amount === 0 || !paymentVoucher?.amount) ?
                        <td width="10%" className="center-text border-black">{paymentVoucher?.subVouchers?.length > 0 ? `₦${numberWithCommas(paymentVoucher?.subVouchers?.reduce(
                            (accumulator, currentValue) => accumulator + stripCommas(currentValue?.amountRequested), 0,
                        ) + stripCommas(paymentVoucher?.amountRequested))}` : `₦${numberWithCommas(schedule?.amount)}`}
                        </td>
                        :
                        <td width="10%" className="center-text border-black">{paymentVoucher?.subVouchers?.length > 0 ? `₦${numberWithCommas(paymentVoucher?.subVouchers?.reduce(
                            (accumulator, currentValue) => accumulator + Number(stripCommas(currentValue?.amount)), 0,
                        ) + stripCommas(paymentVoucher?.amount))}` : `₦${numberWithCommas(schedule?.amount)}`}
                        </td>
                    }
                </tr>
            </table>
            <div className='flex space-around m-t-40'>
                <div>
                    <img className="signature_img" src={schedule?.signingOfficer} alt="signing Officer's signature" />
                    <div className='filter_box_border_b w-200'></div>
                    <p className='center-text'>Signing Officer</p>
                </div>
                <div>
                    <img className="signature_img " src={schedule?.counterSigningOfficerSignature} alt="counter-signing officer's signature" />
                    <div className='filter_box_border_b w-200 '></div>
                    <p className='center-text'>Countersigning Officer</p>
                </div>
            </div>
        </div>
    )
}
export default SchedulePreview;