import React from 'react';

const PreviewFile = ({ src, alt, title, fileType }) => {
  const isImage = fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "svg";
  const isExcel = fileType === 'xlsx' || fileType === "xls";
  const isWord = fileType === "doc" || fileType === "docx";
  const isPdf = fileType === "pdf" ;

  console.log(src, alt, title, fileType)

  return (
    <div className={`bg-white p-20 white-bg   preview-file ${isImage ? 'image' : ''} ${isExcel ? 'excel' : ''} ${isWord ? 'word' : ''}`}>
      {isImage && <a href={src} target="_blank" rel="noopener noreferrer"> <img src={src} alt={alt} className="preview-file__image" /> </a>}
      {isExcel && <a className='preview-file__icon p-20 btn-border' href={src} target="_blank" rel="noopener noreferrer">📊</a>}
      {isWord &&  <a className='preview-file__icon p-20 btn-border' href={src} target="_blank" rel="noopener noreferrer">📄</a>}
      {isPdf &&  <a className='preview-file__icon p-20 btn-border' href={src} target="_blank" rel="noopener noreferrer">📄</a>}
      <div className="m-t-40 preview-file__info">
        <h6 className="preview-file__title">{title}</h6>
        {/* Add more information or styling as needed */}
      </div>
    </div>
  );
};

export default PreviewFile;