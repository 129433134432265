import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ProtectedRoute from './routes/ProtectedRoute';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import UserPortal from './components/UserPortal';
import { MyProvider } from './contexts';
import Connect from './components/home/index';



function App() {
  const history = createBrowserHistory();

  return (
    <MyProvider>
   
      <Router history={history}>
        <div>
          <Switch>
            <Route path="/" exact component={Connect} />

            <ProtectedRoute path="/appropriation/" component={UserPortal} />
            <Route render={() => <h1>Error 404. Page not found.</h1>} />
          </Switch>
          <Toaster />
        </div>
      </Router>
    </MyProvider>
  );
}

export default App;
