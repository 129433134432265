/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { GrDocumentUpdate } from "react-icons/gr";
import { VscPreview } from "react-icons/vsc";
import { GoFileSymlinkFile } from "react-icons/go";
import { RiDashboardLine, RiSettings2Fill } from "react-icons/ri";

const sidebarMenu = () =>{
  if (localStorage.getItem('access') === "DFA" ){
    return [
      { title: 'Dashboard', href: '/appropriation/dashboard', icon: <RiDashboardLine className="icon" /> },
      // { title: 'Appropriation', href: '/appropriation/documentation', icon: <GrDocumentUpdate className="icon" /> },
      // { title: 'Review Appropriation', href: '/appropriation/review-dfa', icon: <VscPreview className="icon" /> },
      { title: 'Appropriation External', href: '/appropriation/other-report', icon: <GoFileSymlinkFile className="icon" /> },
      { title: 'Appropriation Internal', href: '/appropriation/other-report-internal', icon: <GoFileSymlinkFile className="icon" /> },
      // { title: 'Review Appr. Documents', href: '/appropriation/other-review-dfa', icon: <VscPreview className="icon" /> },
      { title: 'Review Ext. Appropriation ', href: '/appropriation/other-review', icon: <VscPreview className="icon" /> },
      { title: 'Review Int. Appropriation ', href: '/appropriation/other-review-int', icon: <VscPreview className="icon" /> },
      { title: 'Settings', href: '/appropriation/settings', icon: <RiSettings2Fill className="icon" /> },
    ];
  }
  else if(localStorage.getItem('access') === "Initiator"){
    return [
      { title: 'Dashboard', href: '/appropriation/dashboard', icon: <RiDashboardLine className="icon" /> },
      // { title: 'Appropriation', href: '/appropriation/appropriation', icon: <GrDocumentUpdate className="icon" /> },
      { title: 'Appropriation Acct', href: '/appropriation/other-report', icon: <GoFileSymlinkFile className="icon" /> },
      // { title: 'Other Financial Report', href: '/appropriation/other-report', icon: <GoFileSymlinkFile className="icon" /> },
    ];
  }
  else{
    return [
      // { title: 'Review Appropriation', href: '/appropriation/review', icon: <VscPreview className="icon" /> },
      { title: 'Review Ext. Appropriation ', href: '/appropriation/other-review', icon: <VscPreview className="icon" /> },
      { title: 'Review Int. Appropriation ', href: '/appropriation/other-review-int', icon: <VscPreview className="icon" /> },
      { title: 'Settings', href: '/appropriation/settings', icon: <RiSettings2Fill className="icon" /> },

    ];
  }
}

export default sidebarMenu;



