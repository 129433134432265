import React, { useState, useEffect } from 'react';
import { getFileExtension, numberWithCommas } from "../../utility/general";
import { Pagination, Spin, Table, Tooltip } from "antd";
import { AiOutlineDelete, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import PreviewFile from './PreviewPicture';
import UploadButton from './UploadButton';
import { IoIosCheckmark, IoIosReturnLeft } from 'react-icons/io';
import { GiCancel } from 'react-icons/gi';
import UploadModal from './UploadModal';

// import './Accordion.css'; // Import the CSS file for styling

const AccordionItem = ({ title, content, showDocumentModal, state, deleteFromSaved, count, setDetails, setType, accPaginationChangeHandler, accPageNumber, accPageSize, editFromSaved, submitFromSaved, attachmentTypes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [recordStat, setRecordStat] = useState({})
  const [uploads, setUploads] = useState([])


  const recImagg = () =>{
    
  }


  useEffect(() => {
    setTables(
      content?.map((entry) => (
        {
          key: entry?.voucher?.voucher?.id,
          createdByName: entry?.voucher?.voucher?.createdByName,
          dateCreated: new Date(entry?.voucher?.voucher?.dateCreated).toUTCString(),
          mda: entry?.voucher?.voucher?.mda,
          voucherType: entry?.voucher?.voucher?.voucherType,
          drTo: entry?.voucher?.voucher?.drTo,
          pvNo: entry?.voucher?.voucher?.pvNo,
          amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.voucher?.amount) + entry?.voucher?.voucher?.subVouchers?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
          )).toFixed(2))}`,
          all: entry
        }
      ))
    )
  }, [content])

  const handleUploadModal = (record) =>{
    setRecordStat(record);
    setShowUploadModal((prev)=>!prev)
  }


  const columns = [
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all?.voucher) }}>View Payment Voucher</a>)

    },
    {
      title: 'APPROVED MEMO',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("memo", record.all?.voucher) }}>View Approved Memo</a>
      )
    },
    
    {
      title: 'PAYMENT CHEQUE',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("cheque", record.all?.voucher) }}>View Payment Cheque</a>
      )
    },
    {
      title: 'APPROPRIATION DOCS',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("appr", record) }}>View Appropriation Docs</a>
      )
    },
  ]

  const savedColumns = [
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all?.voucher) }}>View Payment Voucher</a>)

    },
    {
      title: 'APPROVED MEMO',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("memo", record.all?.voucher) }}>View Approved Memo</a>
      )
    },
    
    {
      title: 'PAYMENT CHEQUE',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("cheque", record.all?.voucher) }}>View Payment Cheque</a>
      )
    },
    {
      title: 'APPROPRIATION DOCS',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("appr", record) }}>View Appropriation Docs</a>
      )
    },
    {
      title: 'UPLOAD DOCUMENT',
      render: (text, record) => (
        <button onClick={() => handleUploadModal(record)} className='btn'>Upload Document</button>
      )
    },
  ]


  console.log(content[0]?.statusDetails === "Pending")
  console.log(content)


  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="accordion-title"
      >
        <div className='w-100 flex space-between'>
          <h1>{title}</h1>
          <h1>{content[0]?.statusDetails === "Pending" ? `Pending with ${content[0]?.pendingOffice}` : (content[0]?.statusDetails === "ApprovalCompleted") ? "Approved" : (content[0]?.statusDetails === "Unasigned") ? "Saved" : "Review"}</h1>
          <div>
            {
              ((content[0]?.statusDetails === "Unasigned" || content[0]?.statusDetails === "Review") && content[0]?.journeyStateId !== 13 && content[0]?.journeyStateId !== 10 && (state === "Saved"))  &&
              <div>
                <Tooltip title={"Submit"}> <AiOutlineSend style={{ marginRight: "16px" }} size={30} color="green" className="pointer" onClick={() => { submitFromSaved(title?.toLowerCase(), content[0]?.voucher?.voucher?.yearOfPayment)}} /> </Tooltip>
                <Tooltip title="Edit"> <MdEdit style={{ marginRight: "16px" }} onClick={() => { editFromSaved(title?.toLowerCase(), content[0]?.voucher?.voucher?.yearOfPayment)}} size={30} color="gray" className="pointer" /> </Tooltip>
                <Tooltip title="Delete"> <AiOutlineDelete style={{ marginRight: "16px" }} onClick={() => { deleteFromSaved(title?.toLowerCase(), content, count)}} size={30} color="red" className="pointer" /> </Tooltip>
              </div>
            }

            {((content[0]?.statusDetails === "Pending" || content[0]?.journeyStateId === 13 || content[0]?.journeyStateId === 10) && (state !== "Saved")) &&
              <div>
                <Tooltip title={localStorage.getItem("access") !== "Auditor General" ? "Approve" : "Review and Revert"}><IoIosCheckmark style={{ marginRight: "10px" }} size={35} color="green" className="pointer" onClick={() => { submitFromSaved(title?.toLowerCase()); setType("approve"); setDetails(content[0]) }} /></Tooltip>
                <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer m-r-10" onClick={() => { submitFromSaved(title?.toLowerCase()); setType("review"); setDetails(content[0]) }} /></Tooltip>
                {/* <Tooltip title="Decline"><GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { submitFromSaved(title?.toLowerCase()); setType("decline") }} /></Tooltip> */}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="accordion-content">
        {isOpen &&
          <div>
            {
              attachmentTypes?.map((type) => {
                if (type?.id !== 6 && type?.id !== 7) {
                  return <div key={type?.id}>
                    <h3>{type?.description}</h3>
                    <div className='flex wrap w-100 '>
                      {
                        content[0]?.uploads &&
                        content[0]?.uploads?.map((document, idx) => {
                          if (document?.attachmentTypeId === type?.id) {
                            return <div
                              key={idx}
                              style={{
                                padding: '2px',
                                marginBottom: '1px',
                                marginTop: '2px',
                              }}
                              className=' flex  mFiles-hover '>
                              <div className=''>
                                <PreviewFile key={idx} src={document?.path} alt={"Uploaded Document"} title={document?.name} fileType={getFileExtension(document?.name)} />
                              </div>
                            </div>
                          }
                        })
                      }
                    </div>

                    <hr />
                  </div>
                }
                else {
                  return;
                }
              })
            }
            <div className="p-10 m-t-40">
              {/* {content?.length > 0 && <h2>Capital Vouchers</h2>} */}
              {content?.length > 0 && <Table
                className='w-100 m-t-20'
                bordered
                dataSource={tables && tables}
                columns={(content[0]?.statusDetails === "Unasigned" || content[0]?.statusDetails === "Review") ? savedColumns : columns}
                rowClassName="editable-row"
                pagination={false}
                scroll={{ x: 400 }}
                loading={{
                  indicator: <div><Spin /></div>, spinning: loading
                }}
              />}
              {count > 0 && (
                <div style={{ marginTop: "20px" }}>
                  <Pagination
                    showSizeChanger
                    pageSize={accPageSize}
                    onShowSizeChange={accPaginationChangeHandler}
                    current={accPageNumber}
                    total={count}
                    // showTotal={true}
                    onChange={accPaginationChangeHandler}
                    // pageSizeOptions={pageSizeOptions}
                    responsive
                  />
                </div>
              )}
            </div>
            {
                showUploadModal && <UploadModal closeModal={() => setShowUploadModal(false)}  uploads={uploads} saved={true} setUploads={setUploads} data={recordStat} />
            }
          </div>
        }
      </div>
    </div>
  );
};

export default AccordionItem;
