import { IoMdClose } from 'react-icons/io';
import ChequeModal from './ChequeModal';
import FinalPage from './FinalPage';
import PreviewFile from './PreviewPicture';
import { getFileExtension } from '../../utility/general';


const DocumentModal = ({ type, paymentVoucher, allData, schedule, closeModal }) => {
    console.log(paymentVoucher)
    console.log(allData)
    return (
        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex flex-h-center"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        {
                            type === "voucher" ?
                                <div>
                                    <FinalPage paymentVoucher={paymentVoucher} schedule={schedule} />
                                </div>
                                :
                                type === "docs" ?
                                    <div className='flex wrap'>
                                        {allData.uploads?.map((file, idx) => (
                                            <PreviewFile key={idx} src={file?.path} alt={"Uploaded Document"} title={file?.name} fileType={getFileExtension(file?.name)} />
                                        ))}
                                    </div>

                                    :
                                    type === "comment" ?
                                        <div className='bg-white p-40'><ChequeModal paymentVoucher={paymentVoucher} schedule={schedule} type="comment" /></div>
                                        : type === "cheque" ?
                                            <div className='bg-white p-40'><ChequeModal paymentVoucher={paymentVoucher} schedule={schedule} type="cheque" /></div>
                                            : type === "appr" ?
                                                <div className='bg-white p-40'>
                                                    {console.log(allData)/* <img style={{ width:'21.7cm'}} src={paymentVoucher?.approvalMemoUrl} alt="A pdf? check link below" /> */}
                                                    {allData?.uploads?.length > 0 && allData?.uploads?.map((url, idx) => (
                                                       url?.attachmentTypeId === 6 && allData?.id === url?.voucherId && <div key={idx} className='m-b-20'><a className='m-t-20' href={url?.path} target="_blank" rel="noopener noreferrer">{`Download ${url?.name}`}</a></div>
                                                    ))
                                                    }
                                                </div>
                                                :
                                                <div className='bg-white p-40'>
                                                    {/* <img style={{ width:'21.7cm'}} src={paymentVoucher?.approvalMemoUrl} alt="A pdf? check link below" /> */}
                                                    {paymentVoucher?.approvalMemoUrls?.length > 0 && paymentVoucher?.approvalMemoUrls.map((url, idx) => (
                                                        <div key={idx} className='m-b-20'><a className='m-t-20' href={url?.path} target="_blank" rel="noopener noreferrer">{`Download ${url?.description}`}</a></div>
                                                    ))
                                                    }
                                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DocumentModal;