/* eslint-disable linebreak-style */
import React, {useState } from 'react';

// import { decodeToken } from '../../utility/auth';

const Greeting = ({ details = {} }) => {
  const [username] = useState(localStorage.getItem('name'));
  const [role] = useState(localStorage.getItem('role'));
  const [access] = useState(localStorage.getItem('access'));
  const [mda] = useState(localStorage.getItem('mda'));

//   useEffect(() => {
//     const r = localStorage.getItem('r');
//     let fn = localStorage.getItem('fn');
//     let ln = localStorage.getItem('ln');

//     r && setRole(decodeToken('r').r);
//     if (fn && ln) {
//       fn = decodeToken('fn').fn;
//       ln = decodeToken('ln').ln;
//       setUsername(`${fn} ${ln}`);
//     }
//   }, []);

  return (
    <div className="greeting m-b-20">
      <p className="m-b-5">
        Good Day
      </p>
      <h3 className="m-b-5">{username || 'Osagie Osaigbovo'}</h3>
      <p className="m-b-5" style={{ fontSize: '0.75rem' }}>
        {role || 'Chief Community Manager'}
      </p>
      <h3 className="" style={{ fontSize: '0.75rem' }}>
        <strong>{`${access}`|| 'Treasury Access'}</strong>
      </h3>
    </div>
  );
};

export default Greeting;
