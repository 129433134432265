import TextInput from "../inputs/TextInput";
import Greeting from "../layouts/Greeting";
import Select from 'react-select';
import { AiOutlineDelete, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { MdDelete, MdEdit } from 'react-icons/md';
import UploadButton from "../layouts/UploadButton";
import PreviewPicture from "../layouts/PreviewPicture";
import { get, put } from "../../utility/fetch";
import { post } from '../../utility/fetch';
import { useEffect, useState } from "react";
import { Pagination, Spin, Table, Tooltip } from "antd";
import DocumentModal from "../layouts/DocumentModal";
import { months, years } from "../../utility/general";
import { getDeptName } from "../../utility/general";
import TextareaInput from "../inputs/TextareaInput";
import { GiCancel } from "react-icons/gi";
import { IoIosCheckmark, IoIosReturnLeft } from "react-icons/io";
import notification from "../../utility/notification";
import ReviewForm from "../layouts/ReviewForm";
import UploadModal from "../layouts/UploadModal";
import AccordionItem from "../layouts/AccordionItem";


const OtherFinancialReviewInternal = () => {

    const [mdas, setMdas] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [paginationLength, setPaginationLength] = useState(0)
    const [pageSize, setPageSize] = useState(30);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalData, setModalData] = useState({});
    const [allData, setAllData] = useState({});
    const [documentType, setDocumentType] = useState("")
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [details, setDetails] = useState([]);
    const [type, setType] = useState("");
    const [currentMonth, setCurrentMonth] = useState("");
    const [uploadUrl, setUploadUrl] = useState([]);
    const [payload, setPayload] = useState({
        mdaId: 0,
        year: 0,
        adminCode: "",
        month: 0,
        comment: "",
        uploadUrl: [

        ]
    });
    const [tables, setTables] = useState([]);
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [recordStat, setRecordStat] = useState({})
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [uploads, setUploads] = useState([])
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [periodYear, setPeriodYear] = useState(2024);
    const [accPageSize, setAccPageSize] = useState(10);
    const [accPageNumber, setAccPageNumber] = useState(1);
    const [voucherComment, setVoucherComment] = useState("")
    const [trigger, setTrigger] = useState(false)

    const [monthlyPagination, setMonthlyPagination] = useState({
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    })
    const [monthlyData, setMonthlyData] = useState(
        {
            january: [],
            february: [],
            march: [],
            april: [],
            may: [],
            june: [],
            july: [],
            august: [],
            september: [],
            october: [],
            november: [],
            december: []
        }
    )

    const getAttachmentTypes = async (month, year) => {
        let res = await get(`/Appropriation/AttachmentType`);
        if (res.status === "success") {
            setAttachmentTypes(res?.data);
            console.log(res);
        }

    }


    const getJanuary = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=January&Year=${periodYear}`)
        if (res.status === "success") {
            console.log(res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access")));
            setMonthlyData(
                {
                    ...monthlyData,
                    january: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                january: res?.data?.totalItemCount
            })
        }
    }

    const getFebruary = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=February&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    february: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                february: res?.data?.totalItemCount
            })
        }
    }

    const getMarch = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=March&Year=${periodYear}`)
        if (res.status === "success") {

            setMonthlyData(
                {
                    ...monthlyData,
                    march: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                march: res?.data?.totalItemCount
            })
        }
    }

    const getApril = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=April&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    april: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                april: res?.data?.totalItemCount
            })
        }
    }

    const getMay = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=May&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    may: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                may: res?.data?.totalItemCount
            })
        }
    }

    const getJune = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=June&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    june: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                june: res?.data?.totalItemCount
            })
        }
    }

    const getJuly = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=July&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    july: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                july: res?.data?.totalItemCount
            })
        }
    }

    const getAugust = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=August&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    august: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                august: res?.data?.totalItemCount
            })
        }
    }

    const getSeptember = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=September&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    september: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                september: res?.data?.totalItemCount
            })
        }
    }

    const getOctober = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=October&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    october: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                october: res?.data?.totalItemCount
            })
        }
    }

    const getNovember = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=November&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    november: res?.data?.items?.filter((item) => item.pendingOffice === localStorage.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                november: res?.data?.totalItemCount
            })
        }
    }

    const getDecember = async () => {
        let res = await get(`/Appropriation/Form/Month/3?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=December&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    december: res?.data?.items?.filter((item) => item?.pendingOffice === localStorage?.getItem("access"))
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                december: res?.data?.totalItemCount
            })
        }
    }


    const toggleReviewModal = (current) => {
        setShowReviewForm(!showReviewForm);
        setCurrentMonth(current)
    }

    const getVouchers = async () => {
        let res = await get(`/Appropriation/Form/FinancialReport?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            setPendingRequests(res?.data?.items);
            console.log(res.data?.items)
        }
    }



    const getSavedDocs = async (mdaId) => {
        let res = await get(`/Appropriation/Form/FinancialReport?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            setPendingRequests(res?.data?.items);
            console.log(res.data?.items)
        }
    }


    const viewDocuments = (type, data) => {
        console.log(data)
        setModalData(data?.voucher);
        setAllData(data)
        setDocumentType(type);
        setShowPictureModal(true);
    }

    const columns = [
        {
            title: 'MDA',
            dataIndex: 'mda',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'ADMINISTRATIVE CODE',
            dataIndex: 'adminCode',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'YEAR',
            dataIndex: 'year',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'COMMENT',
            dataIndex: 'comment',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DOCUMENTS',
            render: (text, record) => (
                <div>
                    {
                        <div className="flex space-around">
                            <button className="w-100 btn" onClick={() => { viewDocuments("docs", record) }}>View Documents</button>
                        </div>
                    }
                </div>
            )
        },
        {
            title: 'ACTION',
            render: (text, record) => (
                <div>
                    {
                        <div className="flex space-around">
                            {
                                <div className="flex space-around">
                                    {/* <Tooltip title="Decline"><GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("decline") }} /></Tooltip> */}
                                    <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("approve") }} /></Tooltip>
                                    <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("review") }} /></Tooltip>

                                </div>
                            }
                        </div>
                    }
                </div>
            )
        },
    ]



    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };


    const getMdas = async () => {
        let res = await get("/Mdas");
        setMdas(res?.data?.map((mda) => (
            {
                value: mda?.id,
                label: mda?.name
            }
        )))

    }

    const handleMda = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                mdaId: value?.value
            }
        ))

    }

    // const handleDocumentMda = (value) => {
    //     getDocumentByMda(value?.value);
    // }

    const handleAdminCode = (e) => {

        setPayload((prev) => (
            {
                ...prev,
                adminCode: e?.target?.value
            }
        ))
    }

    const handleComment = (e) => {

        setPayload((prev) => (
            {
                ...prev,
                comment: e?.target?.value
            }
        ))
    }

    const handleYear = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                year: value?.value
            }
        ))

    }

    const handleMonth = (value) => {
        setPayload((prev) => (
            {
                ...prev,
                month: value?.value
            }
        ))

    }

    useEffect(() => {
        getJanuary();
        getFebruary();
        getMarch();
        getApril();
        getMay();
        getJune();
        getJuly();
        getAugust();
        getSeptember();
        getOctober();
        getNovember();
        getDecember();
    }, [periodYear, accPageSize, accPageNumber, trigger])

    useEffect(() => {
        getAttachmentTypes();
    }, [])


    const showDocumentModal = (type, data) => {
        setModalData(data);
        setAllData(data);
        setDocumentType(type);
        setShowPictureModal(true);
    }

    const accPaginationChangeHandler = (current, pageSize) => {
        setAccPageNumber(current);
        setAccPageSize(pageSize);
    };

    // const recImagg = (value) => {
    //     console.log(value);
    //     let tempDocument = payload?.uploadUrl;
    //     tempDocument.push(
    //         {
    //             docName: value?.docTitle,
    //             docUrl: value?.docPath
    //         }
    //     )

    //     setPayload((prev) => (
    //         {
    //             ...prev,
    //             uploadUrl: tempDocument
    //         }
    //     ))

    // };


    let getApproveData = async (pageNumber, pageSize, currentMonth) => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${3}&Month=${currentMonth}&Year=${periodYear}`)
        if (res.status === "success") {
            return res?.data?.items
        }
        return;
    }

    const approveAppropriation = async (payload) => {
        let res = await put("/Appropriation/Form/Approve", payload)
        if (res?.status === "success") {
            // getPaidVouchers();
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
            setTrigger(!trigger);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }

    const declineAppropriation = async (payload) => {
        let res = await put("/Appropriation/Form/Reject", payload)
        if (res?.status === "success") {
            // getPaidVouchers();
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
            setTrigger(!trigger);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }

    const reviewAppropriation = async (payload) => {
        let res = await put("/Appropriation/Form/Review", payload)
        if (res?.status === "success") {
            // getPaidVouchers();
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
            setTrigger(!trigger);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }



    const approve = async (details, comment) => {
        //  setVoucherType(value?.label)
        // setLoading(true);
        // let requests = [];
        // let load = [];
        // let pageNumber = 1;
        // let pageSize = 100;
        // let tracker = monthlyPagination[currentMonth];

        // while (tracker > 0) {
        //     requests.push(await getApproveData(pageNumber, pageSize, currentMonth));
        //     tracker = tracker - pageSize;
        //     pageNumber++;
        //     setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
        // }

        // console.log(requests);
        // Promise.all(requests)
        //     .then((res) => {
        //         console.log(res)
        //         load = res.flat().map((item) => (
        //             {
        //                 id: item?.id,
        //                 comment: voucherComment,
        //                 uploadUrl:uploadUrl

        //             }
        //         ))
        //         setLoading(false);
        //         if(type === "approve"){
        //             approveAppropriation(load);
        //         }
        //         else if(type === "decline"){
        //             declineAppropriation(load);
        //         }
        //         else{
        //             reviewAppropriation(load);
        //         }

        //     })


       
        let res;

        console.log(details)
        console.log(comment)


        let load = {
            month: details?.voucher?.voucher?.monthOfPayment,
            year: details?.voucher?.voucher?.yearOfPayment,
            uploadUrl: [],
            comment: comment
        }


        if(type === "approve"){
            res = await put("/Appropriation/Form/Internal/Approve", load)
        }
        else{
            res = await put("/Appropriation/Form/Internal/Review", load)
        }
        
        if (res?.status === "success") {
            setTrigger(!trigger);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }

    }



    const del = (id) => {
        let tempArray = payload?.uploadUrl;


        let filteredArray = tempArray.filter((item, idx) => {
            return idx !== id
        })

        setPayload((prev) => (
            {
                ...prev,
                uploadUrl: filteredArray
            }
        ))
    };

    const submitOtherFlow = async (e) => {
        e?.preventDefault();
        let res = await post('/Appropriation/FinancialReport/Form', payload)
        console.log(res);
    }



    useEffect(() => {
        getSavedDocs();
        getMdas();
    }, [])


    return (
        <div className="w-100">
            <Greeting />
            <hr />

            <Select options={years} onChange={(value) => setPeriodYear(value?.value)} />
            <div className="m-t-20">
                {monthlyData?.january?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.january[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.january} count={monthlyPagination.january} />}
                {monthlyData?.february?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.february[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.february} count={monthlyPagination.february} />}
                {monthlyData?.march?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.march[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.march} count={monthlyPagination.march} />}
                {monthlyData?.april?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.april[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.april} count={monthlyPagination.april} />}
                {monthlyData?.may?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.may[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.may} count={monthlyPagination.may} />}
                {monthlyData?.june?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.june[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.june} count={monthlyPagination.june} />}
                {monthlyData?.july?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.july[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.july} count={monthlyPagination.july} />}
                {monthlyData?.august?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.august[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.august} count={monthlyPagination.august} />}
                {monthlyData?.september?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.september[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.september} count={monthlyPagination.september} />}
                {monthlyData?.october?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.october[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.october} count={monthlyPagination.october} />}
                {monthlyData?.november?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.november[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.november} count={monthlyPagination.november} />}
                {monthlyData?.december?.length > 0 && <AccordionItem state={"Pending"} setDetails={setDetails} setType={setType} submitFromSaved={toggleReviewModal} showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.december[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.december} count={monthlyPagination.december} />}
            </div>
            {
                showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData?.all} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
            }
            {
                showUploadModal && <UploadModal closeModal={() => setShowUploadModal(false)} setPendingRequests={setPendingRequests} uploads={uploads} setUploads={setUploads} data={recordStat} />
            }
            {
                showReviewForm && <ReviewForm closeModal={toggleReviewModal} voucherComment={voucherComment} setVoucherComment={setVoucherComment} approve={approve} review={approve} decline={approve} details={details} type={type} loading={loading} />
            }
        </div>
    )
}

export default OtherFinancialReviewInternal;