import { useEffect, useState } from "react";
import Greeting from "../layouts/Greeting";
import { Pagination, Table, Spin } from 'antd';
import SearchInput from '../inputs/SearchInput';
import { get, post, put } from '../../utility/fetch';
import DocumentModal from '../layouts/DocumentModal';

import { numberWithCommas, years } from "../../utility/general";
import UploadButton from "../layouts/UploadButton";
import notification from "../../utility/notification";
import { getDeptName } from "../../utility/general";
import UploadModal from "../layouts/UploadModal";
import { GrStatusGoodSmall } from "react-icons/gr";
import AccordionItem from "../layouts/AccordionItem";
import Select from "react-select";





const Dashboard = () => {

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [paginationLength, setPaginationLength] = useState(0)
    const [searchParam, setSearchParam] = useState("");
    const [table, setTables] = useState(null);
    const [savedTable, setSavedTables] = useState(null);
    const [filterStatus, setFilterStatus] = useState("")
    const [pendingRequests, setPendingRequests] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalData, setModalData] = useState({});
    const [documentType, setDocumentType] = useState("")
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [documentArray, setDocumentArray] = useState([])
    const [appropriationDocuments, setAppropriationDocuments] = useState([])
    const [savedVouchers, setSavedVouchers] = useState([]);
    const [recordStat, setRecordStat] = useState({})
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [allData, setAllData] = useState({});
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [track, setTrack] = useState(false);
    const [accPageSize, setAccPageSize] = useState(10);
    const [accPageNumber, setAccPageNumber] = useState(1);
    const [uploads, setUploads] = useState([])
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [periodYear, setPeriodYear] = useState(2024);
    const [monthlyPagination, setMonthlyPagination] = useState({
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    })
    const [monthlyData, setMonthlyData] = useState(
        {
            january: [],
            february: [],
            march: [],
            april: [],
            may: [],
            june: [],
            july: [],
            august: [],
            september: [],
            october: [],
            november: [],
            december: []
        }
    )


    const getJanuary = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=January&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    january: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                january: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    january: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                january: 0
            })
        }
    }

    const getFebruary = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=February&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    february: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                february: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    february: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                february: 0
            })
        }
    }

    const getMarch = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=March&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    march: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                march: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    march: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                march: 0
            })
        }
    }

    const getApril = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=April&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    april: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                april: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    april: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                april: 0
            })
        }
    }

    const getMay = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=May&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    may: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                may: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    may: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                may: 0
            })
        }
    }

    const getJune = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=June&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    june: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                june: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    june: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                june: 0
            })
        }
    }

    const getJuly = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=July&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    july: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                july: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    july: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                july: 0
            })
        }
    }

    const getAugust = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=August&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    august: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                august: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    august: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                august: 0
            })
        }
    }

    const getSeptember = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=September&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    september: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                september: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    september: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                september: 0
            })
        }
    }

    const getOctober = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=October&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    october: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                october: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    october: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                october: 0
            })
        }
    }

    const getNovember = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=November&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    november: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                november: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    november: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                november: 0
            })
        }
    }

    const getDecember = async () => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=December&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    december: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                december: res?.data?.totalItemCount
            })
        }
        else{
            setMonthlyData(
                {
                    ...monthlyData,
                    december: []
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                december: 0
            })
        }
    }

    const getAttachmentTypes = async (month, year) => {

        let res = await get(`/Appropriation/AttachmentType`);
        if (res.status === "success") {
            setAttachmentTypes(res?.data);
            console.log(res);
        }

    }

    useEffect(() => {
        getJanuary();
        getFebruary();
        getMarch();
        getApril();
        getMay();
        getJune();
        getJuly();
        getAugust();
        getSeptember();
        getOctober();
        getNovember();
        getDecember();
    }, [periodYear, track, accPageNumber, accPageSize])

    const getSavedDocs = async (mdaId) => {
        let res = await get(`/Appropriation/Form/FinancialReport?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            setPendingRequests(res?.data?.items);
            console.log(res.data?.items)
        }
    }

    const getSavedVouchers = async () => {
        let res = await get(`/Appropriation/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}`);
        console.log(res)
        setSavedVouchers(res?.data?.items);
    }

    const showDocumentModal = (type, data) => {
        setModalData(data?.voucher);
        setAllData(data);
        setDocumentType(type);
        setShowPictureModal(true);
    }

    const handleFilter = async (status) => {
        // setFilterStatus(status);
        // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${status}`);
        // if (res?.status === "success") {
        //   setPaginationLength(res?.data?.totalItemCount);
        //   setPendingRequests(res.data.items);
        // }
    }

    const querySearch = async () => {
        // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
        // if (res?.status === "success") {
        //   setPaginationLength(res?.data?.totalItemCount)
        //   setPendingRequests(res.data.items)
        // }
        // console.log(res);
    }

    const handleSearch = (e) => {
        setSearchParam(e.target.value);
        // querySearch();
    }





    useEffect(() => {
        getSavedDocs();
        getSavedVouchers();
        getAttachmentTypes();
    }, [])






    const savedColumns = [
        {
            title: 'PV INITIATOR',
            dataIndex: 'createdByName',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'INITIATOR MDA',
            dataIndex: 'mda',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'VOUCHER TYPE',
            dataIndex: 'voucherType',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DATE OF CREATION',
            dataIndex: 'dateCreated',
            // width: '55%',
            editable: false,
        },
        {
            title: 'NAME OF VENDOR/ORGANIZATION',
            dataIndex: 'drTo',
            // width: '55%',
            editable: false,
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
        },
        {
            title: 'PV NUMBER',
            dataIndex: 'pvNo',
            width: '10%',

        },
        {
            title: 'PAYMENT VOUCHER',
            render: (text, record) => (
                <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

        },
        // {
        //   title: 'VIEW ATTACHED DOCUMENTS',
        //   render: (text, record) => (
        //     <a onClick={() => { showDocumentModal("docs", record) }}>View Attached Documents</a>
        //   )
        // },
        {
            title: 'UPLOADED DOCUMENT',
            render: (text, record) => (
                <a onClick={() => { showDocumentModal("memo", record.all) }}>View Approved Memo</a>
            )
        },
        // {
        //   title: 'STATUS',
        //   render: (text, record) => (
        //     <div >
        //       <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
        //       <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.pendingOffice)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
        //     </div>)
        // },
        // {
        //   title: 'APPROVALS AND COMMENTS',
        //   render: (text, record) => (
        //     <a onClick={() => { showDocumentModal("comment", record.all) }}>View Approvals and Comments</a>
        //   )
        // },
        {
            title: 'STATUS',
            render: (text, record) => (
                <div>
                    <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record?.all?.statusDetails === 'Pending' ? 'bg-amber' : record?.all?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record?.all?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                    <div className="float-right">
                        {
                            record?.all?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record?.all?.pendingOffice)}`
                                :
                                record?.all?.statusDetails === 'ApprovalCompleted' ? 'Approved'
                                    :
                                    record?.all?.statusDetails === 'Rejected' ? 'Declined'
                                        : "Saved"
                        }
                    </div>

                </div>
            )
        },
    ]


    const columns = [
        {
            title: 'MDA',
            dataIndex: 'mda',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'ADMINISTRATIVE CODE',
            dataIndex: 'adminCode',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'YEAR',
            dataIndex: 'year',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'COMMENT',
            dataIndex: 'comment',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DOCUMENTS',
            render: (text, record) => (
                <div>
                    {
                        <div className="flex space-around">
                            <button className="w-100 btn" onClick={() => { showDocumentModal("docs", record) }}>View Documents</button>
                        </div>
                    }
                </div>
            )
        },
        {
            title: 'STATUS',
            render: (text, record) => (
                <div>
                    <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record?.all?.statusDetails === 'Pending' ? 'bg-amber' : record?.all?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record?.all?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                    {
                        <div className="float-right">
                            {
                                record?.all?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record?.all?.pendingOffice)}`
                                    :
                                    record?.all?.statusDetails === 'ApprovalCompleted' ? 'Approved'
                                        :
                                        record?.all?.statusDetails === 'Rejected' ? 'Declined'
                                            :
                                            "Saved"
                            }
                        </div>
                    }
                </div>
            )
        },
    ]


    useEffect(() => {
        setTables(
            pendingRequests.map((entry) => (
                {
                    adminCode: entry?.adminCode,
                    year: entry?.year,
                    mda: entry?.mda,
                    comment: entry?.comment,
                    all: entry
                }
            ))
        )

    }, [pendingRequests])

    const accPaginationChangeHandler = (current, pageSize) => {
        setAccPageNumber(current);
        setAccPageSize(pageSize);
    };

    useEffect(() => {
        setSavedTables(
            savedVouchers?.map((entry) => (
                {
                    createdByName: entry?.voucher?.voucher?.createdByName,
                    dateCreated: new Date(entry?.voucher?.voucher?.dateCreated).toUTCString(),
                    mda: entry?.voucher?.voucher?.mda,
                    voucherType: entry?.voucher?.voucher?.voucherType,
                    drTo: entry?.voucher?.voucher?.drTo,
                    pvNo: entry?.voucher?.voucher?.pvNo,
                    amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.voucher?.amount) + entry?.voucher?.voucher?.subVouchers?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}`,
                    all: entry
                }
            ))
        )

    }, [savedVouchers])

    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };

    useEffect(() => {
        if (searchParam === "") {
            // getVouchers();
        }
        else if (filterStatus !== "") {
            // handleFilter(filterStatus);
        }
        else {
            // querySearch();
        }
    }, [pageSize, pageNumber, searchParam])


    return (
        <div className="w-100">
            <Greeting />
            <div>
                <div className='flex w-100 flex-h-end m-b-10'>
                    <SearchInput
                        onChange={(e) => { handleSearch(e) }}
                        className="w-30"
                    />
                </div>

                <div className="w-100 p-10">
                    <h2 className="m-b-10">External Appropriation</h2>

                    <Select options={years} onChange={(value) => setPeriodYear(value?.value)} />
                    <div className="m-t-20">
                        {monthlyData?.january?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.january?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.january?.data?.items} count={monthlyPagination.january} />}
                        {monthlyData?.february?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.february?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.february?.data?.items} count={monthlyPagination.february} />}
                        {monthlyData?.march?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.march?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.march?.data?.items} count={monthlyPagination.march} />}
                        {monthlyData?.april?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.april?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.april?.data?.items} count={monthlyPagination.april} />}
                        {monthlyData?.may?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.may?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.may?.data?.items} count={monthlyPagination.may} />}
                        {monthlyData?.june?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.june?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.june?.data?.items} count={monthlyPagination.june} />}
                        {monthlyData?.july?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.july?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.july?.data?.items} count={monthlyPagination.july} />}
                        {monthlyData?.august?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.august?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.august?.data?.items} count={monthlyPagination.august} />}
                        {monthlyData?.september?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.september?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.september?.data?.items} count={monthlyPagination.september} />}
                        {monthlyData?.october?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.october?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.october?.data?.items} count={monthlyPagination.october} />}
                        {monthlyData?.november?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.november?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.november?.data?.items} count={monthlyPagination.november} />}
                        {monthlyData?.december?.data?.items?.length > 0 && <AccordionItem  state={"Saved"}  showDocumentModal={showDocumentModal} attachmentTypes={attachmentTypes} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.december?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.december?.data?.items} count={monthlyPagination.december} />}
                    </div>
                    {
                        showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData?.all} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
                    }
                    {
                        showUploadModal && <UploadModal closeModal={() => setShowUploadModal(false)} setPendingRequests={setPendingRequests} uploads={uploads} setUploads={setUploads} data={recordStat} />
                    }
                </div>
            </div>
        </div >
    )
}

export default Dashboard;