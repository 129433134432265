import TextInput from "../inputs/TextInput";
import Greeting from "../layouts/Greeting";
import Select from 'react-select';
import { AiOutlineDelete, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { MdDelete, MdEdit } from 'react-icons/md';
import UploadButton from "../layouts/UploadButton";
import AccordionItem from "../layouts/AccordionItem";
import PreviewPicture from "../layouts/PreviewPicture";
import { get, put, del } from "../../utility/fetch";
import { post } from '../../utility/fetch';
import { useEffect, useState } from "react";
import { Pagination, Spin, Table, Tooltip } from "antd";
import DocumentModal from "../layouts/DocumentModal";
import { months, numberWithCommas, years } from "../../utility/general";
import { getDeptName } from "../../utility/general";
import TextareaInput from "../inputs/TextareaInput";
import notification from "../../utility/notification";
import UploadModal from "../layouts/UploadModal";


const OtherFinancialReport = () => {

    const [mdas, setMdas] = useState([]);
    const [accPageNumber, setAccPageNumber] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [overheadPageNumber, setOverheadPageNumber] = useState(1);
    const [salaryPageNumber, setSalaryPageNumber] = useState(1);
    const [paginationLength, setPaginationLength] = useState(0)
    const [overheadPaginationLength, setOverheadPaginationLength] = useState(0)
    const [salaryPaginationLength, setSalaryPaginationLength] = useState(0)
    const [pageSize, setPageSize] = useState(10);
    const [overheadPageSize, setOverheadPageSize] = useState(10);
    const [salaryPageSize, setSalaryPageSize] = useState(10);
    const [accPageSize, setAccPageSize] = useState(10);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [overheadPendingRequests, setOverheadPendingRequests] = useState([]);
    const [salaryPendingRequests, setSalaryPendingRequests] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [modalData, setModalData] = useState({});
    const [allData, setAllData] = useState({});
    const [documentType, setDocumentType] = useState("")
    const [session, setSession] = useState("")
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [documentsToUpload, setDocumentsToUpload] = useState([])
    const [payload, setPayload] = useState({
        year: 0,
        month: 0,
        uploadUrl: [
        ]
    });
    const [tables, setTables] = useState([]);
    const [overheadTables, setOverheadTables] = useState([]);
    const [salaryTables, setSalaryTables] = useState([]);
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [track, setTrack] = useState(false);
    const [recordStat, setRecordStat] = useState({})
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [uploads, setUploads] = useState([])
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [overheadLoading, setOverheadLoading] = useState(false);
    const [salaryLoading, setSalaryLoading] = useState(false);
    const [periodYear, setPeriodYear] = useState(2024);
    const [monthlyPagination, setMonthlyPagination] = useState({
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    })
    const [monthlyData, setMonthlyData] = useState(
        {
            january: [],
            february: [],
            march: [],
            april: [],
            may: [],
            june: [],
            july: [],
            august: [],
            september: [],
            october: [],
            november: [],
            december: []
        }
    )


    const getJanuary = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=January&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    january: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                january: res?.data?.totalItemCount
            })
        }
    }

    const getFebruary = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=February&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    february: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                february: res?.data?.totalItemCount
            })
        }
    }

    const getMarch = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=March&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    march: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                march: res?.data?.totalItemCount
            })
        }
    }

    const getApril = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=April&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    april: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                april: res?.data?.totalItemCount
            })
        }
    }

    const getMay = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=May&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    may: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                may: res?.data?.totalItemCount
            })
        }
    }

    const getJune = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=June&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    june: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                june: res?.data?.totalItemCount
            })
        }
    }

    const getJuly = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=July&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    july: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                july: res?.data?.totalItemCount
            })
        }
    }

    const getAugust = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=August&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    august: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                august: res?.data?.totalItemCount
            })
        }
    }

    const getSeptember = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=September&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    september: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                september: res?.data?.totalItemCount
            })
        }
    }

    const getOctober = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=October&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    october: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                october: res?.data?.totalItemCount
            })
        }
    }

    const getNovember = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=November&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    november: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                november: res?.data?.totalItemCount
            })
        }
    }

    const getDecember = async () => {
        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=${accPageNumber}&PageSize=${accPageSize}&Month=December&Year=${periodYear}`)
        if (res.status === "success") {
            setMonthlyData(
                {
                    ...monthlyData,
                    december: res
                }
            )
            console.log(res);
            setMonthlyPagination({
                ...monthlyPagination,
                december: res?.data?.totalItemCount
            })
        }
    }

    const updateMonthData = () => {
        getJanuary();
        getFebruary();
        getMarch();
        getApril();
        getMay();
        getJune();
        getJuly();
        getAugust();
        getSeptember();
        getOctober();
        getNovember();
        getDecember();
    }



    useEffect(() => {
        getJanuary();
        getFebruary();
        getMarch();
        getApril();
        getMay();
        getJune();
        getJuly();
        getAugust();
        getSeptember();
        getOctober();
        getNovember();
        getDecember();
    }, [periodYear, track])


    useEffect(() => {
        setTables(
            pendingRequests?.map((entry) => (
                {
                    key: entry?.voucher?.id,
                    createdByName: entry?.voucher?.createdByName,
                    dateCreated: new Date(entry?.voucher?.dateCreated).toUTCString(),
                    mda: entry?.voucher?.mda,
                    voucherType: entry?.voucher?.voucherType,
                    drTo: entry?.voucher?.drTo,
                    pvNo: entry?.voucher?.pvNo,
                    amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}`,
                    all: entry
                }
            ))
        )
    }, [pendingRequests])

    useEffect(() => {
        setOverheadTables(
            overheadPendingRequests?.map((entry) => (
                {
                    key: entry?.voucher?.id,
                    createdByName: entry?.voucher?.createdByName,
                    dateCreated: new Date(entry?.voucher?.dateCreated).toUTCString(),
                    mda: entry?.voucher?.mda,
                    voucherType: entry?.voucher?.voucherType,
                    drTo: entry?.voucher?.drTo,
                    pvNo: entry?.voucher?.pvNo,
                    amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}`,
                    all: entry
                }
            ))
        )
    }, [overheadPendingRequests])

    useEffect(() => {
        setSalaryTables(
            salaryPendingRequests?.map((entry) => (
                {
                    key: entry?.voucher?.id,
                    createdByName: entry?.voucher?.createdByName,
                    dateCreated: new Date(entry?.voucher?.dateCreated).toUTCString(),
                    mda: entry?.voucher?.mda,
                    voucherType: entry?.voucher?.voucherType,
                    drTo: entry?.voucher?.drTo,
                    pvNo: entry?.voucher?.pvNo,
                    amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}`,
                    all: entry
                }
            ))
        )
    }, [salaryPendingRequests])

    const showDocumentModal = (type, data) => {
        setModalData(data);
        setAllData(data);
        setDocumentType(type);
        setShowPictureModal(true);
    }

    // const submitFromSaved = async (e) => {
    //     console.log(e?.all?.id)
    //     let payload = [e?.all?.id]
    //     let res = await put(`/Appropriation/FinancialReport/Submit?model=${e?.all?.id}`)
    //     if (res?.status === "success") {
    //         getSavedDocs();
    //         notification({
    //             type: 'success',
    //             message: res?.message
    //         });
    //     }
    //     else {
    //         notification({
    //             type: 'error',
    //             message: res?.message
    //         });
    //     }
    //     console.log(res);
    // }

    const editSaved = (e) => {
        console.log(e)
        // setSearchParam(e.target.value);
        // querySearch();
    }

    const deleteSaved = (e) => {
        // setSearchParam(e.target.value);
        // querySearch();
    }

    const getSavedDocs = async (mdaId) => {
        let res = await get(`/Appropriation/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        if (res.status === "success") {
            // setPendingRequests(res?.data?.items);
            console.log(res.data?.items)
        }
    }


    const viewDocuments = (type, data) => {
        setModalData(data?.voucher);
        setAllData(data)
        setDocumentType(type);
        setShowPictureModal(true);
    }


    const handleUploadModal = (e) => {
        setRecordStat(e);
        setShowUploadModal(true);
    }

    const columns = [
        {
            title: 'PV INITIATOR',
            dataIndex: 'createdByName',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'INITIATOR MDA',
            dataIndex: 'mda',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'VOUCHER TYPE',
            dataIndex: 'voucherType',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DATE OF CREATION',
            dataIndex: 'dateCreated',
            // width: '55%',
            editable: false,
        },
        {
            title: 'NAME OF VENDOR/ORGANIZATION',
            dataIndex: 'drTo',
            // width: '55%',
            editable: false,
        },
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
        },
        {
            title: 'PV NUMBER',
            dataIndex: 'pvNo',
            width: '10%',

        },
        {
            title: 'PAYMENT VOUCHER',
            render: (text, record) => (
                <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

        },
        {
            title: 'APPROVED MEMO',
            render: (text, record) => (
                <a onClick={() => { showDocumentModal("memo", record.all) }}>View Approved Memo</a>
            )
        },
        {
            title: 'PAYMENT CHEQUE',
            render: (text, record) => (
              <a onClick={() => { showDocumentModal("cheque", record.all?.voucher) }}>View Payment Cheque</a>
            )
          },
          {
            title: 'APPROPRIATION DOCS',
            render: (text, record) => (
              <a onClick={() => { showDocumentModal("appr", record) }}>View Appropriation Docs</a>
            )
          },
    ]


    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };

    const overheadPaginationChangeHandler = (current, pageSize) => {
        setOverheadPageNumber(current);
        setOverheadPageSize(pageSize);
    };

    const salaryPaginationChangeHandler = (current, pageSize) => {
        setSalaryPageNumber(current);
        setSalaryPageSize(pageSize);
    };

    const accPaginationChangeHandler = (current, pageSize) => {
        setAccPageNumber(current);
        setAccPageSize(pageSize);
    };


    const editFromSaved = async (monthy, data) => {
        window.scrollTo(0, 0);
        console.log(data)
        console.log(monthy)

        let res = await get(`/Appropriation/External/Paginated/Month?PageNumber=1&PageSize=10&Month=${monthy}&Year=${data}`)
        if (res?.status === "success") {
            console.log(res)
            setUploads(res?.data?.items[0].uploads?.map((uploads) => (
                {
                    docName: uploads?.name,
                    docUrl: uploads?.path,
                    attachmentTypeId: uploads?.attachmentTypeId
                }
            )))
        }

        setSession("Review");
        let tempMonth = months.find((month) => month?.label?.toLocaleLowerCase() === monthy?.toLocaleLowerCase())
        let tempYear = years.find((year) => year?.label === data)
        setPayload({
            ...payload,
            year: tempYear,
            month: tempMonth
        })

    }

    let getSavedData = async (pageNumber, pageSize, currentMonth) => {
        let res = await get(`/Appropriation/Form/Month?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${1}&Month=${currentMonth}&Year=${periodYear}`)
        if (res.status === "success") {
            return res?.data?.items?.filter((item) => item.statusDetails === "Unasigned")
        }
        return;
    }

    const submitAppropriation = async (payload) => {
        let res = await put("/Appropriation/Submit", payload)
        if (res?.status === "success") {
            setTrack(!track);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }

    const submitFromSaved = async (month, year) => {
        //  setVoucherType(value?.label)
        // setLoading(true);
        // let requests = [];
        // let load = [];
        // let pageNumber = 1;
        // let pageSize = 100;
        // let tracker = monthlyPagination[currentMonth];

        // while (tracker > 0) {
        //     requests.push(await getSavedData(pageNumber, pageSize, currentMonth));
        //     tracker = tracker - pageSize;
        //     pageNumber++;
        //     setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
        // }

        // console.log(requests);
        // Promise.all(requests)
        //     .then((res) => {
        //         console.log(res)
        //         load = res.flat().map((item) => (
        //             item?.id
        //         ))
        //         setLoading(false);
        //         submitAppropriation(load);
        //     })

        let load = {
            month: month,
            year: year
        }

        let res = await put("/Appropriation/External/Submit", load)
        if (res?.status === "success") {
            setTrack(!track);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }



    const handleYear = (value) => {
        getPaidVouchers(payload?.month?.label, value?.value);
        getPaidOverheadVouchers(months[value?.value]?.label, payload?.year?.value);
        getPaidSalaryVouchers(months[value?.value]?.label, payload?.year?.value);
        setPayload((prev) => (
            {
                ...prev,
                year: value
            }
        ))

    }

    const handleMonth = (value) => {
        getPaidVouchers(months[value?.value]?.label, payload?.year?.value);
        getPaidOverheadVouchers(months[value?.value]?.label, payload?.year?.value);
        getPaidSalaryVouchers(months[value?.value]?.label, payload?.year?.value);

        setPayload((prev) => (
            {
                ...prev,
                month: value
            }
        ))

    }


    const recImagg = (value) => {
        console.log(value);
        let tempDocument = payload?.uploadUrl;
        tempDocument.push(
            {
                docName: value?.docTitle,
                docUrl: value?.docPath
            }
        )

        setPayload((prev) => (
            {
                ...prev,
                uploadUrl: tempDocument
            }
        ))

    };


    const getPaidVouchers = async (month, year) => {
        if (payload?.month !== "" && payload?.year !== 0) {
            let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${1}&Month=${month}&Year=${year}`);
            if (res.status === "success") {
                setPendingRequests(res?.data?.items);
                setPaginationLength(res?.data?.totalItemCount);
                console.log(res);
            }
            else {
                setPendingRequests([]);
                setPaginationLength(0);
                console.log(res);
            }
        }

    }


    const getPaidRecurrentVouchers = async (month, year, paginationL) => {
        if (payload?.month !== "" && payload?.year !== 0) {
            let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${10}&VoucherTypeId=${6}&Month=${month}&Year=${year}`);
            if (res.status === "success") {
                setOverheadPendingRequests((prev) => (
                    Array?.isArray(prev) && prev?.concat(res?.data?.items)
                ));

                setOverheadPaginationLength(res?.data?.totalItemCount + paginationL);
                console.log(res);
            }
            else {
                setOverheadPendingRequests([]);
                setOverheadPaginationLength(0);
                console.log(res);
            }
        }

    }

    const getPaidOverheadVouchers = async (month, year) => {
        if (payload?.month !== "" && payload?.year !== 0) {
            let res = await get(`/Treasury/Paginated/Approved?PageNumber=${overheadPageNumber}&PageSize=${10}&VoucherTypeId=${5}&Month=${month}&Year=${year}`);
            if (res.status === "success") {
                setOverheadPendingRequests(res?.data?.items);
                getPaidRecurrentVouchers(month, year, res?.data?.totalItemCount);
                console.log(res);
            }
            else {
                setOverheadPendingRequests([]);
                setOverheadPaginationLength(0);
                console.log(res);
            }
        }

    }

    const getPaidSalaryVouchers = async (month, year) => {
        if (payload?.month !== "" && payload?.year !== 0) {
            let res = await get(`/Treasury/Paginated/Approved?PageNumber=${salaryPageNumber}&PageSize=${salaryPageSize}&VoucherTypeId=${2}&Month=${month}&Year=${year}`);
            if (res.status === "success") {
                setSalaryPendingRequests(res?.data?.items);
                setSalaryPaginationLength(res?.data?.totalItemCount);
                console.log(res);
            }
            else {
                setSalaryPendingRequests([]);
                setSalaryPaginationLength(0);
                console.log(res);
            }
        }

    }


    const getAttachmentTypes = async (month, year) => {

        let res = await get(`/Appropriation/AttachmentType`);
        if (res.status === "success") {
            setAttachmentTypes(res?.data);
            console.log(res);
        }

    }

    useEffect(() => {
        getPaidVouchers(payload?.month?.label, payload?.year?.value);

    }, [pageSize, pageNumber])

    useEffect(() => {

        getPaidSalaryVouchers(payload?.month?.label, payload?.year?.value)

    }, [salaryPageNumber, salaryPageSize])

    useEffect(() => {

        getPaidOverheadVouchers(payload?.month?.label, payload?.year?.value)
    }, [overheadPageNumber, overheadPageSize])

    useEffect(() => {
        getJanuary();
        getFebruary();
        getMarch();
        getApril();
        getMay();
        getJune();
        getJuly();
        getAugust();
        getSeptember();
        getOctober();
        getNovember();
        getDecember();
    }, [accPageSize, accPageNumber])


    const deleteDoc = (id) => {
        let tempArray = payload?.uploadUrl;
        let filteredArray = tempArray.filter((item, idx) => {
            return idx !== id
        })
        setPayload((prev) => (
            {
                ...prev,
                uploadUrl: filteredArray
            }
        ))
    };


    // let getData = async (pageNumber, pageSize, monthy, yeary) => {
    //     console.log(months[payload?.month]?.label)
    //     console.log(payload?.year?.value)
    //     let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${1}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
    //     if (res.status === "success") {
    //         return res?.data?.items
    //     }
    //     return;
    // }

    // let getRecurrentData = async (pageNumber, pageSize, monthy, yeary, overheadData) => {
    //     console.log(months[payload?.month]?.label)
    //     console.log(payload?.year?.value)
    //     let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${5}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
    //     if (res.status === "success") {
    //         return res?.data?.items?.concat(overheadData);
    //     }
    //     return;
    // }

    // let getOverheadData = async (pageNumber, pageSize, monthy, yeary) => {
    //     console.log(months[payload?.month]?.label)
    //     console.log(payload?.year?.value)
    //     let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${6}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
    //     if (res.status === "success") {
    //         return await getRecurrentData(pageNumber, pageSize, monthy, yeary, res?.data?.items)
    //     }
    //     return;
    // }

    // let getSalaryData = async (pageNumber, pageSize, monthy, yeary) => {
    //     console.log(months[payload?.month]?.label)
    //     console.log(payload?.year?.value)
    //     let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${2}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
    //     if (res.status === "success") {
    //         return res?.data?.items
    //     }
    //     return;
    // }


    let getDeleteData = async (pageNumber, pageSize, monthy, yeary) => {
        console.log(months[payload?.month]?.label)
        console.log(payload?.year?.value)
        // let res = await get(`/Treasury/Paginated/Approved?PageNumber=${pageNumber}&PageSize=${pageSize}&VoucherTypeId=${1}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
        let res = await get(`/Appropriation/Form/Month?PageNumber=${pageNumber}&PageSize=${pageSize}&Month=${payload?.month?.label || monthy}&Year=${payload?.year?.value || yeary}`)
        if (res.status === "success") {
            return res?.data?.items
        }
        return;
    }



    // const saveAppropriation = async (payload) => {
    //     let res = await post("/Appropriation/Form", payload)
    //     if (res?.status === "success") {
    //         setTrack(!track);
    //         notification({
    //             type: 'success',
    //             message: res?.message
    //         });
    //         setPayload([]);
    //         setUploads([]);
    //         setPendingRequests([]);
    //     }
    //     else {
    //         notification({
    //             type: 'error',
    //             message: res?.message
    //         });
    //     }
    //     console.log(res);
    // }


    const saveExternalAppropriation = async (payload) => {
        let res = await post("/Appropriation/External/Form", payload)
        if (res?.status === "success") {
            setTrack(!track);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }

    const updateExternalAppropriation = async (payload) => {
        let res = await put("/Appropriation/External/Update", payload)
        if (res?.status === "success") {
            setTrack(!track);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            notification({
                type: 'error',
                message: res?.message
            });
        }
        console.log(res);
    }

    const deleteAppropriation = async (payload) => {
        console.log(payload);
        let res = await del("/Appropriation/Form", payload)
        if (res?.status === "success") {
            setTrack(!track);
            notification({
                type: 'success',
                message: res?.message
            });
            setPayload([]);
            setUploads([]);
            setPendingRequests([]);
        }
        else {
            // notification({
            //     type: 'error',
            //     message: res?.message
            // });
        }
        console.log(res);
    }



    // const submitOtherFlow = async (e) => {
    //     //  setVoucherType(value?.label) 
    //     setLoading(true);
    //     let requests = [];
    //     let load = [];
    //     let pageNumber = 1;
    //     let pageSize = 100;
    //     let tracker = paginationLength;

    //     while (tracker > 0) {
    //         requests.push(await getData(pageNumber, pageSize));
    //         tracker = tracker - pageSize;
    //         pageNumber++;
    //         setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
    //     }

    //     console.log(requests);
    //     Promise.all(requests)
    //         .then((res) => {
    //             console.log(res)
    //             load = res.flat().map((item) => {
    //                 return {
    //                     voucherId: item?.voucher?.id,
    //                     uploadUrl: uploads
    //                 }
    //             })
    //             setLoading(false);
    //             saveAppropriation(load);

    //         })

    // }

    // const submitOtherOverheadFlow = async (e) => {
    //     //  setVoucherType(value?.label)
    //     setLoading(true);
    //     let requests = [];
    //     let load = [];
    //     let pageNumber = 1;
    //     let pageSize = 100;
    //     let tracker = overheadPaginationLength;

    //     while (tracker > 0) {
    //         requests.push(await getOverheadData(pageNumber, pageSize));
    //         tracker = tracker - pageSize;
    //         pageNumber++;
    //         setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
    //     }

    //     console.log(requests);
    //     Promise.all(requests)
    //         .then((res) => {
    //             console.log(res)
    //             load = res.flat().map((item) => {
    //                 return {
    //                     voucherId: item?.voucher?.id,
    //                     uploadUrl: uploads
    //                 }
    //             })
    //             setLoading(false);
    //             saveAppropriation(load);

    //         })

    // }

    // const submitOtherSalaryFlow = async (e) => {
    //     //  setVoucherType(value?.label)
    //     setLoading(true);
    //     let requests = [];
    //     let load = [];
    //     let pageNumber = 1;
    //     let pageSize = 100;
    //     let tracker = salaryPaginationLength;

    //     while (tracker > 0) {
    //         requests.push(await getSalaryData(pageNumber, pageSize));
    //         tracker = tracker - pageSize;
    //         pageNumber++;
    //         setUploadProgress(((pageSize / tracker) * 100).toFixed(2));
    //     }

    //     console.log(requests);
    //     Promise.all(requests)
    //         .then((res) => {
    //             console.log(res)
    //             load = res.flat().map((item) => {
    //                 return {
    //                     voucherId: item?.voucher?.id,
    //                     uploadUrl: uploads
    //                 }
    //             })
    //             setLoading(false);
    //             saveAppropriation(load);
    //         })

    // }


    const submitFlow = async () => {
        // await submitOtherOverheadFlow();
        // await submitOtherFlow();
        // await submitOtherSalaryFlow();
        // let load = {
        //     month:payload?.month?.label,
        //     year: payload?.year?.value,
        //     uploadUrl: uploads
        //   }

        // saveExternalAppropriation(load)

        let load = {
            month: payload?.month?.label?.toLocaleLowerCase(),
            year: payload?.year?.value,
            uploadUrl: uploads
        }

        if (session === "Review") {
            updateExternalAppropriation(load);
        }
        else {
            saveExternalAppropriation(load);
        }
    }

    const deleteFromSaved = async (e, data, count) => {
        //  setVoucherType(value?.label)
        console.log(e)
        console.log(data)
        console.log(months.find(month => month?.label?.toLowerCase() === e.toLowerCase()))
        setPayload({
            year: {
                value: data[6]?.voucher?.voucher?.yearOfPayment,
                label: data[6]?.voucher?.voucher?.yearOfPayment
            },
            month: months.find(month => month?.label?.toLowerCase() === e.toLowerCase())

        })
        setLoading(true);
        let requests = [];
        let load = [];
        let pageNumber = 1;
        let pageSize = 100;
        let tracker = count;

        while (tracker > 0) {
            requests.push(await getDeleteData(pageNumber, pageSize, e, data[6]?.voucher?.voucher?.yearOfPayment));
            tracker = tracker - pageSize;
            pageNumber++;
            setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
        }

        console.log(requests);
        Promise.all(requests)
            .then((res) => {
                console.log(res)
                load = res.flat().map((item) => (item?.id))
                console.log(load)
                setLoading(false);
                deleteAppropriation(load);
            })

    }

    console.log(payload);

    useEffect(() => {
        getSavedDocs();
        getAttachmentTypes();
        getSavedDocs();
        // getMdas();
    }, [])


    return (
        <div className="w-100">
            <Greeting />
            <hr />
            <div className="w-100 m-t-20 flex">
                <form className="w-50">
                    <h2 className="m-b-20">SELECT APPROPRIATION PERIOD</h2>


                    <label htmlFor="">Month:</label>
                    <Select onChange={handleMonth} value={payload?.month} options={months} placeholder="Select Month" className="m-b-20" />

                    <label htmlFor="">Year:</label>
                    <Select onChange={handleYear} value={payload?.year} options={years} placeholder="Select Year" className="m-b-20" />

                </form>
                <div className="m-l-20 w-48">
                    <h2 className="m-b-20 ">ADD APPROPRIATION DOCUMENTS</h2>
                    <div>
                        {
                            attachmentTypes?.map((type) => {
                                if (type?.id !== 6 && type?.id !== 7) {
                                    return <div key={type?.id}>
                                        <h4>{type?.description}</h4>
                                        <button onClick={() => handleUploadModal(type)} closeModal={() => setShowUploadModal(false)} className="btn">Upload {type?.description} Documents</button>
                                        {
                                            uploads &&
                                            uploads?.map((document, idx) => {
                                                if (document?.attachmentTypeId === type?.id) {
                                                    return <div
                                                        key={idx}
                                                        style={{
                                                            padding: '2px',
                                                            marginBottom: '1px',
                                                            marginTop: '2px',
                                                            width: '100%',
                                                        }}
                                                        // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                                                        className='flex-between mFiles-hover flex-align'>
                                                        <div className='flex-align'>
                                                            <AiOutlinePaperClip />
                                                            <a target="_blank" href={document?.docUrl || document?.path} style={{ marginLeft: '3px', marginBottom: '0px' }}>
                                                                {document?.docName || document?.name}
                                                            </a>
                                                            {/* <div
                                                                className='pointer'
                                                                style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                                                                onClick={() => {
                                                                    del(idx);
                                                                }}>
                                                                <MdDelete style={{ fontSize: '16px' }} />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        }
                                        <hr />
                                    </div>
                                }
                                else {
                                    return;
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            <button onClick={submitFlow} className="btn w-100 m-t-20 " type="submit">{session === "Review" ? "Update" : "Save"}</button>
            {uploadProgress > 0 && (
                <div className='m-t-10'>
                    {/* <p>Upload Progress: {uploadProgress}%</p> */}
                    <div style={{ width: '100%', border: '1px solid #ccc' }}>
                        <div
                            style={{
                                width: `${uploadProgress}%`,
                                height: '12px',
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                textAlign: 'center',
                                lineHeight: '16px',
                            }}
                        >
                            {uploadProgress}%
                        </div>
                    </div>

                </div>
            )}
            <div className="p-10 m-t-40">
                {pendingRequests?.length > 0 && <h2>Capital Vouchers</h2>}
                {pendingRequests?.length > 0 && <Table
                    className='w-100 m-t-20'
                    bordered
                    dataSource={tables && tables}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 400 }}
                    loading={{
                        indicator: <div><Spin /></div>, spinning: loading
                    }}
                />}
                {paginationLength > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Pagination
                            showSizeChanger
                            pageSize={pageSize}
                            onShowSizeChange={paginationChangeHandler}
                            current={pageNumber}
                            total={paginationLength}
                            // showTotal={true}
                            onChange={paginationChangeHandler}
                            // pageSizeOptions={pageSizeOptions}
                            responsive
                        />
                    </div>
                )}
            </div>
            <div className="p-10 m-t-40">
                {overheadPendingRequests?.length > 0 && <h2>Overhead Vouchers</h2>}
                {overheadPendingRequests?.length > 0 && <Table
                    className='w-100 m-t-20'
                    bordered
                    dataSource={overheadTables && overheadTables}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 400 }}
                    loading={{
                        indicator: <div><Spin /></div>, spinning: overheadLoading
                    }}
                />}
                {overheadPaginationLength > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Pagination
                            showSizeChanger
                            pageSize={overheadPageSize}
                            onShowSizeChange={overheadPaginationChangeHandler}
                            current={overheadPageNumber}
                            total={overheadPaginationLength}
                            // showTotal={true}
                            onChange={overheadPaginationChangeHandler}
                            // pageSizeOptions={pageSizeOptions}
                            responsive
                        />
                    </div>
                )}
            </div>
            <div className="p-10 m-t-40">
                {salaryPendingRequests?.length > 0 && <h2>Salary Vouchers</h2>}
                {salaryPendingRequests?.length > 0 && <Table
                    className='w-100 m-t-20'
                    bordered
                    dataSource={salaryTables && salaryTables}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 400 }}
                    loading={{
                        indicator: <div><Spin /></div>, spinning: salaryLoading
                    }}
                />}
                {salaryPaginationLength > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Pagination
                            showSizeChanger
                            pageSize={salaryPageSize}
                            onShowSizeChange={salaryPaginationChangeHandler}
                            current={salaryPageNumber}
                            total={salaryPaginationLength}
                            // showTotal={true}
                            onChange={salaryPaginationChangeHandler}
                            // pageSizeOptions={pageSizeOptions}
                            responsive
                        />
                    </div>
                )}
            </div>

            <Select options={years} onChange={(value) => setPeriodYear(value?.value)} />
            <div className="m-t-20">
                {monthlyData?.january?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.january?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.january?.data?.items} count={monthlyPagination.january} />}
                {monthlyData?.february?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.february?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.february?.data?.items} count={monthlyPagination.february} />}
                {monthlyData?.march?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.march?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.march?.data?.items} count={monthlyPagination.march} />}
                {monthlyData?.april?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.april?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.april?.data?.items} count={monthlyPagination.april} />}
                {monthlyData?.may?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.may?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.may?.data?.items} count={monthlyPagination.may} />}
                {monthlyData?.june?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.june?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.june?.data?.items} count={monthlyPagination.june} />}
                {monthlyData?.july?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.july?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.july?.data?.items} count={monthlyPagination.july} />}
                {monthlyData?.august?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.august?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.august?.data?.items} count={monthlyPagination.august} />}
                {monthlyData?.september?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.september?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.september?.data?.items} count={monthlyPagination.september} />}
                {monthlyData?.october?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.october?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.october?.data?.items} count={monthlyPagination.october} />}
                {monthlyData?.november?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.november?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.november?.data?.items} count={monthlyPagination.november} />}
                {monthlyData?.december?.data?.items?.length > 0 && <AccordionItem state={"Saved"} editFromSaved={editFromSaved} deleteFromSaved={deleteFromSaved} submitFromSaved={submitFromSaved} showDocumentModal={showDocumentModal} accPaginationChangeHandler={accPaginationChangeHandler} accPageNumber={accPageNumber} accPageSize={accPageSize} title={monthlyData?.december?.data?.items[0].voucher?.voucher?.monthOfPayment} content={monthlyData?.december?.data?.items} count={monthlyPagination.december} />}
            </div>
            {
                showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData?.all} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
            }
            {
                showUploadModal && <UploadModal closeModal={() => setShowUploadModal(false)} updateMonthData={updateMonthData} setPendingRequests={setPendingRequests} uploads={uploads} setUploads={setUploads} data={recordStat}  />
            }
        </div>
    )
}

export default OtherFinancialReport;