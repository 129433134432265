import { IoMdClose } from 'react-icons/io';
import ChequeModal from './ChequeModal';
import FinalPage from './FinalPage';
import PreviewFile from './PreviewPicture';
import UploadButton from './UploadButton';
import { put } from "../../utility/fetch";
import notification  from "../../utility/notification";

import { useState } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';


const UploadModal = ({ data, closeModal, updateMonthData, uploads, setUploads, saved, pendingRequests, setPendingRequests }) => {

    const recImagg = (value) => {
        setUploads([...uploads, {
            docName: value?.docTitle,
            docUrl: value?.docPath,
            attachmentTypeId: data?.id || 6
        }])

    }

    console.log(data)

    const attachDocument = async () => {
        let load = [
            {
                voucherId: data?.all?.voucherId,
                uploadUrl: uploads,
                id: data?.all?.id
            }
        ]

        console.log(load)

        let res = await put("/Appropriation/Form", load)

        if (res.status === "success") {
            // updateMonthData();
            notification({
                type: 'success',
                message: res?.message
            });
            setUploads([]);
            
        }
        else{
            notification({
                type: 'error',
                message: res?.message
            });
        }

    }

    return (
        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} className='white-bg bg-white p-20'>
                <div className=" no-padding">
                    <div
                        className="m-t-10 p-t-10 flex flex-col white-bg bg-white p-10"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <p className=''>Upload Documents:</p>
                        <UploadButton sendImagg={recImagg} />
                        {
                            uploads &&
                            uploads?.map((document, idx) => {
                                if (document?.attachmentTypeId === data?.id) {
                                    return <div
                                        key={idx}
                                        style={{
                                            padding: '2px',
                                            marginBottom: '1px',
                                            marginTop: '2px',
                                            width: '100%',
                                        }}
                                        // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                                        className='flex-between mFiles-hover flex-align'>
                                        <div className='flex-align'>
                                            <AiOutlinePaperClip />
                                            <a target="_blank" href={document?.docUrl} style={{ marginLeft: '3px', marginBottom: '0px' }}>
                                                {document?.docName}
                                            </a>
                                            {/* <div
                                            className='pointer'
                                            style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                                            onClick={() => {
                                                del(idx);
                                            }}>
                                            <MdDelete style={{ fontSize: '16px' }} />
                                        </div> */}
                                        </div>
                                    </div>
                                }
                                else if(document?.attachmentTypeId === 6){
                                    return <div
                                    key={idx}
                                    style={{
                                        padding: '2px',
                                        marginBottom: '1px',
                                        marginTop: '2px',
                                        width: '100%',
                                    }}
                                    // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                                    className='flex-between mFiles-hover flex-align'>
                                    <div className='flex-align'>
                                        <AiOutlinePaperClip />
                                        <a target="_blank" href={document?.docUrl} style={{ marginLeft: '3px', marginBottom: '0px' }}>
                                            {document?.docName}
                                        </a>
                                        {/* <div
                                        className='pointer'
                                        style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                                        onClick={() => {
                                            del(idx);
                                        }}>
                                        <MdDelete style={{ fontSize: '16px' }} />
                                    </div> */}
                                    </div>
                                </div>
                                }

                            })
                        }

                        {saved && <button onClick={attachDocument} className='btn m-t-20'>Attach Document</button>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UploadModal;