import { useEffect, useState } from "react";
import Greeting from "../layouts/Greeting";
import { Pagination, Table, Spin } from 'antd';
import SearchInput from '../inputs/SearchInput';
import { get, put } from '../../utility/fetch';
import DocumentModal from '../layouts/DocumentModal';
import ReviewForm from '../layouts/ReviewForm';
import { GrStatusGoodSmall } from "react-icons/gr"
import { numberWithCommas } from "../../utility/general";
import { GiCancel } from 'react-icons/gi';
import { IoIosCheckmark } from 'react-icons/io';
import { Tooltip } from 'antd';
import { IoIosReturnLeft } from 'react-icons/io';
import notification from "../../utility/notification";




const ReviewDocumentation = () => {

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [paginationLength, setPaginationLength] = useState(0)
  const [searchParam, setSearchParam] = useState("");
  const [table, setTables] = useState(null)
  const [filterStatus, setFilterStatus] = useState("")
  const [pendingRequests, setPendingRequests] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [allData, setAllData] = useState({});
  const [documentType, setDocumentType] = useState("")
  const [showPictureModal, setShowPictureModal] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [details, setDetails] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState("");

  const approve = async (data, comment) => {
    // setLoading(true);
    // const { voucher, schedule } = comment;

    const payload =
      [
        {
          id: data?.all?.id,
          comment
        }
      ]

    try {
      const res = await put(
        '/Appropriation/Form/Approve',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Approved Successfully"
        });
        getVouchers();

        setLoading(false);
      }
      else {
        console.log(res)
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };


  const decline = async (data, comment) => {
    const payload =
      [
        {
          id: data?.all?.id,
          comment
        }
      ]

    try {
      const res = await put(
        '/Appropriation/Form/Reject',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Declined Successfully"
        });
        getVouchers();

        setLoading(false);
      }
      else {
        console.log(res)
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };


  const review = async (data, comment) => {
    const payload =
      [
        {
          id: data?.all?.id,
          comment
        }
      ]

    try {
      const res = await put(
        '/Appropriation/Form/Review',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction sent back for review successfully"
        });
        getVouchers();

        setLoading(false);
      }
      else {
        console.log(res)
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };



  const getVouchers = async () => {
    try {
      let res = await get(`/Appropriation/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}`);
      console.log(res)
      if (res.status === "success") {
        if (res?.data) {
          setPendingRequests(res?.data?.items);
        }
        else {
          setPendingRequests([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const toggleReviewModal = () => {
    setShowReviewForm(!showReviewForm);
  }

  const showDocumentModal = (type, data) => {
    setModalData(data?.voucher);
    setAllData(data)
    setDocumentType(type);
    setShowPictureModal(true);
  }

  const handleFilter = async (status) => {
    // setFilterStatus(status);
    // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${status}`);
    // if (res?.status === "success") {
    //   setPaginationLength(res?.data?.totalItemCount);
    //   setPendingRequests(res.data.items);
    // }
  }

  const querySearch = async () => {
    // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    // if (res?.status === "success") {
    //   setPaginationLength(res?.data?.totalItemCount)
    //   setPendingRequests(res.data.items)
    // }
    // console.log(res);
  }

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    // querySearch();
  }

  useEffect(() => {
    getVouchers();
  }, [])

  const getDeptName = (name) => {
    if (name == 'DFA') {
      return 'DFA'
    }
    if (name == "Audit") {
      return 'Internal Auditor'
    }
    if (name == "FinalAccount") {
      return 'Final Account'
    }
    if (name == "AG") {
      return 'Accountant General'
    }
    if (name == "ExpenseControl") {
      return 'Expenditure Control'
    }
    if (name == "MgtAccount") {
      return 'Management Account'
    }
    if (name == "TreasuryCashAccount") {
      return 'Treasury Cash Account'
    }
    if (name == "PS") {
      return 'PS'
    }
    if (name == "Inspectorate") {
      return 'Inspectorate'
    }
    if (name == "Governor") {
      return 'Governor'
    }
  }


  const columns = [
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

    },
    {
      title: 'SCHEDULE FORM',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("docs", record.all) }}>View Attached Documents</a>)

    },

    {
      title: 'APPROVALS AND COMMENTS',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("comment", record.all) }}>View Approvals and Comments</a>
      )
    },
    {
      title: 'ACTION',
      render: (text, record) => (
        <div>
          {

            <div className="flex space-around">
              <Tooltip title="Decline"><GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("decline") }} /></Tooltip>
              <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("approve") }} /></Tooltip>
              <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setDetails(record); setShowReviewForm(true); setType("review") }} /></Tooltip>

            </div>
          }
        </div>
      )
    },
  ]


  useEffect(() => {
    Array.isArray(pendingRequests) && setTables(
      pendingRequests ? pendingRequests?.map((entry) => (
        {
          createdByName: entry?.voucher?.voucher?.createdByName,
          dateCreated: new Date(entry?.voucher?.voucher?.dateCreated).toUTCString(),
          mda: entry?.voucher?.voucher?.mda,
          voucherType: entry?.voucher?.voucher?.voucherType,
          drTo: entry?.voucher?.voucher?.drTo,
          pvNo: entry?.voucher?.voucher?.pvNo,
          amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.voucher?.amount) + entry?.voucher?.voucher?.subVouchers?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
          )).toFixed(2))}`,
          all: entry
        }
      )) : []
    )

  }, [pendingRequests])

  const paginationChangeHandler = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (searchParam === "") {
      getVouchers();
    }
    else if (filterStatus !== "") {
      handleFilter(filterStatus);
    }
    else {
      querySearch();
    }
  }, [pageSize, pageNumber, searchParam])


  return (
    <div className="w-100">
      <Greeting />
      <div>
        <div className='flex w-100 flex-h-end m-b-10'>
          <SearchInput
            onChange={(e) => { handleSearch(e) }}
            className="w-30"
          />
        </div>

        <div className="w-100 p-10">
          <Table
            className='w-100'
            bordered
            dataSource={table && table}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ x: 400 }}
            loading={{
              indicator: <div> <Spin /> </div>, spinning: tableLoading
            }}
          />

          {paginationLength > 0 && (
            <div style={{ marginTop: "20px" }}>
              <Pagination
                showSizeChanger
                pageSize={pageSize}
                onShowSizeChange={paginationChangeHandler}
                current={pageNumber}
                total={paginationLength}
                // showTotal={true}
                onChange={paginationChangeHandler}
                // pageSizeOptions={pageSizeOptions}
                responsive
              />
            </div>
          )}

          {
            showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
          }
          {showReviewForm && <ReviewForm closeModal={toggleReviewModal} approve={approve} review={review} decline={decline} details={details} type={type} loading={loading} />}



        </div>
      </div>
    </div>
  )
}

export default ReviewDocumentation;