import { Spin } from "antd";
import React, { useEffect, useState } from "react";

import { IoMdClose } from "react-icons/io";
import TextareaInput from "../inputs/TextareaInput";
import UploadButton from "../layouts/UploadButton";



const ReviewForm = ({ approve, ag, decline, details, voucherComment, setVoucherComment, review, type, isApproved, closeModal, loading }) => {


  const submit = () => {
    // If the governor has not appproved, hardcoded values to bypass validation


    if (type === "approve" || type === "MGT") {
      approve(details, voucherComment);
      closeModal();
    }
    else if (type === "decline") {
      decline(details, voucherComment);
      closeModal();
    }
    else if (type === "review") {
      review(details, voucherComment);
      closeModal();
    }

  }

  const handleVChange = (e) => {
    setVoucherComment(e.target.value);
  }

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box w-80">
        <div>
          <div className="content">

            <TextareaInput
              className="w-100 m-b-10"
              name="comment"
              label="Comments on Documents:"
              type="text"
              rows="5"
              onChange={handleVChange}
            />

            {/* {
              <UploadButton />
            } */}

            {<div className="flex space-between w-100">
              <button
                onClick={submit}
                disabled={loading}
                className="btn btn-large w-100 m-t-20"
              >
                {loading && <Spin />} {(type === "approve" || type === "MGT") ? "Approve" : (type === "review") ? "Review" : "Decline"}
              </button>
            </div>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
