import { useEffect, useState } from "react";
import Greeting from "../layouts/Greeting";
import { Pagination, Table, Spin, Tooltip } from 'antd';
import SearchInput from '../inputs/SearchInput';
import { get, post, put } from '../../utility/fetch';
import DocumentModal from '../layouts/DocumentModal';
import { GrStatusGoodSmall } from "react-icons/gr"
import { AiOutlineDelete, AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { MdDelete, MdEdit } from 'react-icons/md';
import { numberWithCommas } from "../../utility/general";
import UploadButton from "../layouts/UploadButton";
import notification from "../../utility/notification";
import { getDeptName } from "../../utility/general";
import UploadModal from "../layouts/UploadModal";


const tableHeaders = [
  'PV Initiator',
  'Initiator MDA',
  'Voucher Type',
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Payment Voucher',
  'Schedule Form',
  'Uploaded Document',
  'Payment Cheque',
  'Status',
  'Approvals and Comments',
  'action'
];


const Documentation = () => {

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [paginationLength, setPaginationLength] = useState(0)
  const [searchParam, setSearchParam] = useState("");
  const [table, setTables] = useState(null);
  const [savedTable, setSavedTables] = useState(null);
  const [filterStatus, setFilterStatus] = useState("")
  const [pendingRequests, setPendingRequests] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [documentType, setDocumentType] = useState("")
  const [showPictureModal, setShowPictureModal] = useState(false);
  const [documentArray, setDocumentArray] = useState([])
  const [appropriationDocuments, setAppropriationDocuments] = useState([])
  const [savedVouchers, setSavedVouchers] = useState([]);
  const [recordStat, setRecordStat] = useState({})
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [allData, setAllData] = useState({});

  const getVouchers = async () => {
    let res = await get(`/Treasury/Form/Approved`);
    if (res.status === "success") {
      console.log(res)
      setPendingRequests(res?.data);
    }
  }

  const getSavedVouchers = async () => {
    let res = await get(`/Appropriation/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}`);
    console.log(res)
    setSavedVouchers(res?.data?.items);
  }

  const showDocumentModal = (type, data) => {
    setModalData(data?.voucher);
    setAllData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }

  const handleFilter = async (status) => {
    setFilterStatus(status);
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${status}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount);
      setPendingRequests(res.data.items);
    }
  }

  const querySearch = async () => {
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res.data.items)
    }
    console.log(res);
  }

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    // querySearch();
  }

  const handleUploadModal = (e) => {

    setRecordStat(e);
    setShowUploadModal(true);
  }

  const submitFromSaved = async (e) => {
    console.log(e?.all?.id)
    let payload = [e?.all?.id]
    let res = await put("/Appropriation/Submit", payload)

    if(res?.status === "success"){
      getSavedVouchers();
      getVouchers();
      notification({
      type: 'success',
      message: res?.message
    });
    }
    else{
      notification({
        type: 'error',
        message: res?.message
      });
    }

    // setSearchParam(e.target.value);
    // querySearch();
  }

  const editSaved = (e) => {
    console.log(e)
    // setSearchParam(e.target.value);
    // querySearch();
  }

  const deleteSaved = (e) => {
    // setSearchParam(e.target.value);
    // querySearch();
  }


  const handleAppropriationDocuments = () => {

    setAppropriationDocuments([{
      voucherId: pendingRequests[0]?.voucher?.id,
      uploadUrl: documentArray
    }]);

    // else{
    //   notification({
    //     message: "Please upload at least one document",
    //     type: 'warning'
    //   })
    // }
  }
  // const recImagg = (value) => {
  //   console.log(value);
  //   console.log(recordStat);
  //   console.log(pendingRequests);
  //   console.log(pendingRequests[0]?.voucher?.id === recordStat?.voucher?.id);
  //   console.log(pendingRequests[0]?.voucher?.id);
  //   console.log(recordStat?.voucher?.id);
  //   setPendingRequests(pendingRequests?.map((voucher) => {
  //     if (voucher?.voucher?.id === recordStat?.voucher?.id) {
  //       return { ...voucher, uploads: [...voucher?.voucher?.uploads, value] };
  //     }
  //   }));

  //   handleAppropriationDocuments();
  // };

  console.log(pendingRequests)
  console.log(recordStat)

  const del = (id) => {
    console.log(id)
    console.log(documentArray)
    let filteredArray = documentArray.filter((item, idx) => {
      return idx !== id
    })
    setDocumentArray(filteredArray);
  };

  const submitDocuments = async () => {
    
    let payload = pendingRequests?.map((item)=>{
      if(item?.voucher?.uploads?.length > 0) {
        return {
          voucherId: item?.voucher?.id,
          uploadUrl: item?.voucher?.uploads.map((item)=>(
            {
              docName: item?.docTitle,
              docPath: item?.docPath
            }
          ))
        }
      }
      return null;
    }).filter((element) => element !== null)

    console.log(payload);

    let res = await post("/Appropriation/Form", payload)

    if(res?.status === "success"){
      getSavedVouchers();
      getVouchers();
      notification({
      type: 'success',
      message: res?.message
    });
    }
    else{
      notification({
        type: 'error',
        message: res?.message
      });
    }
    
    console.log(res)

    // console.log(appropriationDocuments)
  }

  useEffect(() => {
    getVouchers();
    getSavedVouchers();
  }, [])



  // const showUs = (tt)=>{
  // console.log(tt)
  // }
  const columns = [
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

    },
    {
      title: 'UPLOADED DOCUMENT',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("memo", record.all) }}>View Approved Memo</a>
      )
    },
    
    // {
    //   title: 'STATUS',
    //   render: (text, record) => (
    //     <div >
    //       <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
    //       <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.pendingOffice)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
    //     </div>)
    // },
    // {
    //   title: 'APPROVALS AND COMMENTS',
    //   render: (text, record) => (
    //     <a onClick={() => { showDocumentModal("comment", record?.all) }}>View Approvals and Comments</a>
    //   )
    // },
    {
      title: 'ACTION',
      width: "25%",
      render: (text, record) => (
        <div >
          {/* <UploadButton record={recordStat} sendImagg={recImagg} /> */}
          <button onClick={() => handleUploadModal(record)} closeModal={() => setShowUploadModal(false)} className="btn">Upload</button>
          {
            record?.all?.voucher?.uploads &&
            record?.all?.voucher?.uploads?.map((document, idx) => (
              <div
                style={{
                  padding: '2px',
                  marginBottom: '1px',
                  marginTop: '2px',
                  width: '100%',
                }}
                // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                className='flex-between mFiles-hover flex-align'>
                <div className='flex-align'>
                  <AiOutlinePaperClip />
                  <a target="_blank" href={document?.docPath} style={{ marginLeft: 'px', marginBottom: '0px' }}>
                    {document?.docTitle}
                  </a>
                  <div
                    className='pointer'
                    style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                    onClick={() => {
                      del(idx);
                    }}>
                    <MdDelete style={{ fontSize: '16px' }} />
                  </div>
                </div>
              </div>
            ))
          }

        </div>
      )
    },
  ]

  const savedColumns = [
    {
      title: 'PV INITIATOR',
      dataIndex: 'createdByName',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'INITIATOR MDA',
      dataIndex: 'mda',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'VOUCHER TYPE',
      dataIndex: 'voucherType',
      //   width: '55%',
      editable: false,
    },
    {
      title: 'DATE OF CREATION',
      dataIndex: 'dateCreated',
      // width: '55%',
      editable: false,
    },
    {
      title: 'NAME OF VENDOR/ORGANIZATION',
      dataIndex: 'drTo',
      // width: '55%',
      editable: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
    },
    {
      title: 'PV NUMBER',
      dataIndex: 'pvNo',
      width: '10%',

    },
    {
      title: 'PAYMENT VOUCHER',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("voucher", record?.all) }}>View Payment Voucher</a>)

    },
    // {
    //   title: 'VIEW ATTACHED DOCUMENTS',
    //   render: (text, record) => (
    //     <a onClick={() => { showDocumentModal("docs", record) }}>View Attached Documents</a>
    //   )
    // },
    {
      title: 'UPLOADED DOCUMENT',
      render: (text, record) => (
        <a onClick={() => { showDocumentModal("memo", record.all) }}>View Approved Memo</a>
      )
    },
    // {
    //   title: 'STATUS',
    //   render: (text, record) => (
    //     <div >
    //       <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
    //       <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.pendingOffice)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
    //     </div>)
    // },
    // {
    //   title: 'APPROVALS AND COMMENTS',
    //   render: (text, record) => (
    //     <a onClick={() => { showDocumentModal("comment", record.all) }}>View Approvals and Comments</a>
    //   )
    // },
    {
      title: 'ACTION',
      render: (text, record) => (
        <div>
          {
            record?.all?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record?.all?.pendingOffice)}`
              :
              record?.all?.statusDetails === 'ApprovalCompleted' ? 'Approved'
                :
                record?.all?.statusDetails === 'Rejected' ? 'Declined'
                  : <div>
                    <Tooltip title="Submit"> <AiOutlineSend style={{ marginRight: "16px" }} size={30} color="green" className="pointer" onClick={() => {submitFromSaved(record) }} /></Tooltip> 
                    <Tooltip title="Edit"> <MdEdit style={{ marginRight: "16px" }} size={30} color="gray" className="pointer" onClick={() => {editSaved(record) }} /></Tooltip> 
                    <Tooltip title="Delete"><AiOutlineDelete style={{ marginRight: "16px" }} size={30} color="red" className="pointer" onClick={() => {deleteSaved(record) }} /></Tooltip>    
                  </div>}
        </div>
      )
    },
  ]


  useEffect(() => {
    setTables(
      pendingRequests?.map((entry) => (
        {
          key: entry?.voucher?.id,
          createdByName: entry?.voucher?.createdByName,
          dateCreated: new Date(entry?.voucher?.dateCreated).toUTCString(),
          mda: entry?.voucher?.mda,
          voucherType: entry?.voucher?.voucherType,
          drTo: entry?.voucher?.drTo,
          pvNo: entry?.voucher?.pvNo,
          amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
          )).toFixed(2))}`,
          all: entry
        }
      ))
    )

  }, [pendingRequests])

  useEffect(() => {


    setSavedTables(
      savedVouchers?.map((entry) => (
        {
          createdByName: entry?.voucher?.voucher?.createdByName,
          dateCreated: new Date(entry?.voucher?.voucher?.dateCreated).toUTCString(),
          mda: entry?.voucher?.voucher?.mda,
          voucherType: entry?.voucher?.voucher?.voucherType,
          drTo: entry?.voucher?.voucher?.drTo,
          pvNo: entry?.voucher?.voucher?.pvNo,
          amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.voucher?.amount) + entry?.voucher?.voucher?.subVouchers?.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
          )).toFixed(2))}`,
          all: entry
        }
      ))
    )

  }, [savedVouchers])

  const paginationChangeHandler = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (searchParam === "") {
      getVouchers();
    }
    else if (filterStatus !== "") {
      handleFilter(filterStatus);
    }
    else {
      querySearch();
    }
  }, [pageSize, pageNumber, searchParam])


  return (
    <div className="w-100">
      <Greeting />
      <div>
        <div className='flex w-100 flex-h-end m-b-10'>
          <SearchInput
            onChange={(e) => { handleSearch(e) }}
            className="w-30"
          />
        </div>

        <div className="w-100 p-10">

          <Table
            className='w-100'
            bordered
            dataSource={table && table}
            columns={columns}
            rowKey={record => record?.key}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ x: 400 }}
            loading={{
              indicator: <div> <Spin /> </div>, spinning: tableLoading
            }}
          />

          {paginationLength > 0 && (
            <div style={{ marginTop: "20px" }}>
              <Pagination
                showSizeChanger
                pageSize={pageSize}
                onShowSizeChange={paginationChangeHandler}
                current={pageNumber}
                total={paginationLength}
                // showTotal={true}
                onChange={paginationChangeHandler}
                // pageSizeOptions={pageSizeOptions}
                responsive
              />
            </div>
          )}

          <button className="btn w-100 m-t-20 m-b-20 m-b-40" onClick={submitDocuments}>Save</button>

          <Table
            className='w-100 m-t-40'
            bordered
            dataSource={savedTable && savedTable}
            columns={savedColumns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{ x: 400 }}
            loading={{
              indicator: <div> <Spin /> </div>, spinning: tableLoading
            }}
          />

          {paginationLength > 0 && (
            <div style={{ marginTop: "20px" }}>
              <Pagination
                showSizeChanger
                pageSize={pageSize}
                onShowSizeChange={paginationChangeHandler}
                current={pageNumber}
                total={paginationLength}
                // showTotal={true}
                onChange={paginationChangeHandler}
                // pageSizeOptions={pageSizeOptions}
                responsive
              />
            </div>
          )}

          {
            showPictureModal && <DocumentModal closeModal={() => setShowPictureModal(false)} allData={allData?.all} paymentVoucher={modalData?.voucher} schedule={modalData?.schedule} type={documentType} />
          }
          {
            showUploadModal && <UploadModal closeModal={() => setShowUploadModal(false)} setPendingRequests={setPendingRequests} pendingRequests={pendingRequests} data={recordStat} />
          }
        </div>
      </div>
    </div >
  )
}

export default Documentation;