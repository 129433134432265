import React, { useEffect, useState, useContext, useMemo } from 'react';
import { get } from '../../utility/fetch';
// import { exportTableData } from '../../utility/exportToExcel';
// import useFilteredData from '../../hooks/useFilteredData';
// import { UserInfoContext } from '../../contexts';
import useMdaData from '../../utility/useMda';
// import SelectInput from '../inputs/SelectInput';
// import { formatTableHeaderToCamelCase } from '../../utility/general';
import SettingsCard from '../layouts/SettingsCard';

// Icons
import { FiAward } from 'react-icons/fi';
import { GrStatusGood } from 'react-icons/gr';
import {
  managingMDAColumns
  
} from '../layouts/settingsColumn';
// import RoleForm from '../layouts/RoleForm';
// import SequenceForm from '../layouts/SequenceForm';
// import SequenceCategoryForm from '../layouts/SequenceCategoryForm';
// import ModuleAccessLevelForm from '../layouts/ModuleAccessLevelForm';
// import ModuleAccessForm from '../layouts/ModuleAccessForm';
import ManageMDAForm from '../layouts/ManageMDAForm';

let approvalDataMemo;

const Settings = () => {
//   const { currentUser } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  let mdas = useMdaData();
  const [sequenceData, setSequenceData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [AccessLevelData, setAccessLevelData] = useState([]);
  const [moduleAccessData, setModuleAccessData] = useState([]);
  const [showSequenceModal, setShowSequenceModal] = useState(false);
  const [showModuleAccessModal, setShowModuleAccessModal] = useState(false);
  const [showMDAModal, setShowMDAModal] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false) 
  const [showInspectorateModal, setShowInspectorateModal] = useState(false) 
  const [showFinalModal, setShowFinalModal] = useState(false)
  const [auditors, setAuditors] = useState([])
  const [expenditureOfficers, setExpenditureOfficer] = useState([])
  const [inspectorateOfficers, setInspectorateOfficer] = useState([])
  const [inspectorate, setInspectorate] = useState([])
  const [finalOfficers, setFinalOfficer] = useState([])
  const [showModuleAccessLevelModal, setShowModuleAccessLevelModal] =
    useState(false);
  const [showSequencyCategoryModal, setShowSequenceCategoryModal] =
    useState(false);
  const [details, setDetails] = useState(null);
  const [sequenceSearchParams, setSequenceSearchParams] = useState('');
  const [sequenceCategorySearchParams, setSequenceCategorySearchParams] =
    useState('');
  const [accessLevelSearchParams, setAccessLevelSearchParams] = useState('');
  const [moduleAccessSearchParams, setModuleAccessSearchParams] = useState('');
  const [auditorMDASearchParams, setAuditorMDASearchParams] = useState('');


  // const fetchSequence = async () => {
  //   try {
  //     const { status, data } = await get(`/Setup/Sequence`, {}, true);
  //     if (status === 'success') {
  //       approvalDataMemo = data;

  //       setSequenceData(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  console.log(moduleAccessData)

  const getAuditors = async () => {
    try {
      const { status, data } = await get(`/ModuleUsers/Auditors`);
      if (status === 'success') {
        setAuditors(data);
      }
    } catch (error) {
      console.log(error);
    }
  }



  const getFinalAccount = async () => {
    try {
      const { status, data } = await get(`/ModuleUsers/FinalAccount`);
      if (status === 'success') {
        setFinalOfficer(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getExpenditureControl = async () => {
    try {
      const { status, data } = await get(`/ModuleUsers/ExpenseControl`);
      if (status === 'success') {
        setExpenditureOfficer(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getInspectorate = async () => {
    try {
      const { status, data } = await get(`/ModuleUsers/Inspectorate`);
      if (status === 'success') {
        setInspectorateOfficer(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const searchAuditorMDA = async () => {
    const { data } = await get(`/ModuleAccess`, {}, true);
    const result = data.filter((d) =>
      d.treasuryUser
        .toLowerCase()
        .includes(moduleAccessSearchParams.toLowerCase())
    );
    setModuleAccessData(result);
  };


  // const searchSequence = async () => {
  //   const { data } = await get(`/Setup/Sequence`, {}, true);
  //   const result = data.filter((d) =>
  //     d.name.toLowerCase().includes(sequenceSearchParams.toLowerCase())
  //   );
  //   setSequenceData(result);
  // };

  // const fetchSequenceCategory = async () => {
  //   try {
  //     const { status, data } = await get(`/Setup/Category`, {}, true);
  //     if (status === 'success') {
  //       approvalDataMemo = data;

  //       setCategoryData(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const searchSequenceCategory = async () => {
  //   const { data } = await get(`/Setup/Category`, {}, true);
  //   const result = data.filter((d) =>
  //     d.description
  //       .toLowerCase()
  //       .includes(sequenceCategorySearchParams.toLowerCase())
  //   );
  //   setCategoryData(result);
  // };


  const toggleAssignMDAModal = () => {
    if (showMDAModal === true) {
      setDetails(null)
    }
    setShowMDAModal(!showMDAModal);

    getAuditors();
  };

  const toggleAssignFinalModal = () => {
    if (showFinalModal === true) {
      setDetails(null)
    }
    setShowFinalModal(!showFinalModal);

    getFinalAccount();
  };

  const toggleAssignExpenseModal = () => {
    if (showExpenseModal === true) {
      setDetails(null)
    }
    setShowExpenseModal(!showExpenseModal);

    getExpenditureControl();
  };

  const toggleAssignInspectorateModal = () => {
    if (showInspectorateModal === true) {
      setDetails(null)
    }
    setShowInspectorateModal(!showInspectorateModal);

    getInspectorate();
  };

  const fetchModuleAccessLevel = async () => {
    try {
      const { status, data } = await get(`/AccessRoles`, {}, true);
      if (status === 'success') {
        approvalDataMemo = data;

        setAccessLevelData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchAccessLevel = async () => {
    const { data } = await get(`/AccessLevel`, {}, true);
    const result = data.filter((d) =>
      d.accessLevel
        .toLowerCase()
        .includes(accessLevelSearchParams.toLowerCase())
    );
    setAccessLevelData(result);
  };

  const fetchModuleAccess = async () => {
    try {
      const { status, data } = await get(`/ModuleUsers/AuditorGenStaff`, {}, true);
      if (data && status === 'success') {
        approvalDataMemo = data;

        setModuleAccessData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchModuleAccess = async () => {
    const { data } = await get(`/ModuleAccess`, {}, true);
    const result = data.filter((d) =>
      d.treasuryUser
        .toLowerCase()
        .includes(moduleAccessSearchParams.toLowerCase())
    );
    setModuleAccessData(result);
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Settings';
    setTitle('Settings');
  };

  useEffect(() => {
    // fetchSequence();
    // fetchSequenceCategory();
    fetchModuleAccess();
    fetchModuleAccessLevel();
    performPageMetaActions();
    getAuditors();
    getExpenditureControl();
    getInspectorate();
    getFinalAccount();
  }, []);

  const toggleSequenceModal = () => {
    setShowSequenceModal(!showSequenceModal);
    // fetchSequence();
  };

  const toggleSequenceCategoryModal = () => {
    setShowSequenceCategoryModal(!showSequencyCategoryModal);
    // fetchSequenceCategory();
  };

  const toggleModuleAccessLevelModal = () => {
    setShowModuleAccessLevelModal(!showModuleAccessLevelModal);

    fetchModuleAccess();
  };

  const toggleModuleAccessModal = () => {
    if (showModuleAccessModal === true) {
      setDetails(null)
    }
    setShowModuleAccessModal(!showModuleAccessModal);

    fetchModuleAccessLevel();
  };

  return (
    <div className="w-100">

      <div className="wrap column">
       
        <div className="col-12 no-margin">
          <SettingsCard
            header="MDA Auditor General Officer's Settings"
            title="Assign MDA to Auditor General's Officers"
            icon={<FiAward fontSize="30px" />}
            column={managingMDAColumns}
            data={moduleAccessData}
            createSequence={toggleAssignFinalModal}
            details={details}
            setDetails={setDetails}
            onChange={(e) => setModuleAccessSearchParams(e.target.value)}
            onSearch={searchAuditorMDA}
            fetchModuleAccess={fetchModuleAccess}
          />
        </div>
       
      </div>

      {/* {showModuleAccessLevelModal && (
        <ModuleAccessLevelForm
          closeModal={toggleModuleAccessLevelModal}
          details={details}
        />
      )}

      {showSequenceModal && (
        <SequenceForm closeModal={toggleSequenceModal} details={details} />
      )}

      {showSequencyCategoryModal && (
        <SequenceCategoryForm
          closeModal={toggleSequenceCategoryModal}
          details={details}
        />
      )} */}

      {/* {showModuleAccessModal && (
        <ModuleAccessForm
          closeModal={toggleModuleAccessModal}
          mdas={mdas}
          fetchModuleAccess={fetchModuleAccess}
          details={details}
        />
      )} */}

      {showMDAModal && (
        <ManageMDAForm
          closeModal={toggleAssignMDAModal}
          mdas={mdas}
          fetchModuleAccess={fetchModuleAccess}
          details={details}
          officer={auditors}
          office={"audit"}
        />
      )}

      {showFinalModal && (
        <ManageMDAForm
          closeModal={toggleAssignFinalModal}
          mdas={mdas}
          fetchModuleAccess={fetchModuleAccess}
          details={details}
          officer={moduleAccessData}
          office={"final"}
        />
      )}

     


    </div>
  );
};

export default Settings;
