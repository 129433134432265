/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Switch, Route } from 'react-router';
import Documentation from "../components/pages/Documentation"
import Settings from "../components/pages/Settings"
import ProtectedRoute from "./ProtectedRoute"
import ReviewDocumentation from '../components/pages/ReviewDocumentation';
import OtherFinancialReport from '../components/pages/OtherFinancialReport';
import OtherFinancialReportInternal from '../components/pages/OtherFinancialReportInternal';
import OtherFinancialReview from '../components/pages/OtherFinancialReview';
import OtherFinancialReviewInternal from '../components/pages/OtherFinancialReviewInternal';
import ReviewDocumentationDFA from '../components/pages/ReviewDocumentationDFA';
import OtherFinancialReviewDFA from '../components/pages/OtherFinancialReviewDFA';
import Dashboard from '../components/pages/Dashboard';





export default () => (
  <Switch>
    <ProtectedRoute
      path="/appropriation/documentation"
      exact
      component={Documentation}
    />
    <ProtectedRoute
      path="/appropriation/dashboard"
      exact
      component={Dashboard}
    />
    <ProtectedRoute
      path="/appropriation/settings"
      exact
      component={Settings}
    />
    <ProtectedRoute
      path="/appropriation/review"
      exact
      component={ReviewDocumentation}
    />
    <ProtectedRoute
      path="/appropriation/review-dfa"
      exact
      component={ReviewDocumentationDFA}
    />
    <ProtectedRoute
      path="/appropriation/other-report"
      exact
      component={OtherFinancialReport}
    />
     <ProtectedRoute
      path="/appropriation/other-report-internal"
      exact
      component={OtherFinancialReportInternal}
    />
    <ProtectedRoute
      path="/appropriation/other-review"
      exact
      component={OtherFinancialReview}
    />
    <ProtectedRoute
      path="/appropriation/other-review-int"
      exact
      component={OtherFinancialReviewInternal}
    />


    <ProtectedRoute
      path="/appropriation/other-review-dfa"
      exact
      component={OtherFinancialReviewDFA}
    />
    
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
);
